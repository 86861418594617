import { SvgIcon } from "@material-ui/core";

export function VehicleTypeLogo(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="23.074"
      height="19.193"
      viewBox="0 0 23.074 19.193"
      {...props}
    >
      <g id="vehicle" transform="translate(-17929.926 -17324.166)">
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M19.787,18.623H18.623a1.165,1.165,0,0,1-1.164-1.164V16.3H3.492v1.164a1.165,1.165,0,0,1-1.164,1.164H1.164A1.165,1.165,0,0,1,0,17.459V8.148L2.421,1.175A1.741,1.741,0,0,1,4.073,0H9.2V1.746H4.073L2.328,6.984H9.2v4.9a2,2,0,0,0,2,2h9.749v3.58A1.165,1.165,0,0,1,19.787,18.623ZM4.073,9.311a1.746,1.746,0,1,0,1.746,1.746A1.748,1.748,0,0,0,4.073,9.311Z"
          transform="translate(17929.926 17324.736)"
          fill={props?.color || "fff"}
        />
        <g
          id="Group_59175"
          data-name="Group 59175"
          transform="translate(17941.869 17324)"
        >
          <g
            id="Group_59171"
            data-name="Group 59171"
            transform="translate(-1 0)"
          >
            <rect
              id="Rectangle_4989"
              data-name="Rectangle 4989"
              width="5"
              height="5"
              rx="1"
              transform="translate(0.131 0.166)"
              fill={props?.color || "fff"}
            />
            <rect
              id="Rectangle_4990"
              data-name="Rectangle 4990"
              width="5"
              height="5"
              rx="1"
              transform="translate(0.131 7.166)"
              fill={props?.color || "fff"}
            />
          </g>
          <g
            id="Group_59170"
            data-name="Group 59170"
            transform="translate(6.394 0)"
          >
            <rect
              id="Rectangle_4992"
              data-name="Rectangle 4992"
              width="5"
              height="5"
              rx="1"
              transform="translate(-0.264 0.166)"
              fill={props?.color || "fff"}
            />
            <rect
              id="Rectangle_4991"
              data-name="Rectangle 4991"
              width="5"
              height="5"
              rx="1"
              transform="translate(-0.264 7.166)"
              fill={props?.color || "fff"}
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
