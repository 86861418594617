import * as React from "react";
import { stringify } from "query-string";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  required,
  useQuery,
  BooleanInput,
  TabbedForm,
  FormTab,
  EditButton,
  FormDataConsumer,
  FileInput,
} from "react-admin";
import {
  AvatarField,
  ChangeEntityStatus,
  ChangeStatus,
  StatusDisplay,
  VehicleType,
} from "../components/CustomFields";
import {
  CircularProgress,
  Grid,
  makeStyles,
  Box,
  Divider,
  Typography,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { BASE_URL } from "../config";
import axios from "axios";

export const CompanyList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    sort={{ field: "created_at", order: "DESC" }}
    {...props}
  >
    <Datagrid size="medium" padding="normal">
      <AvatarField source="logo" />
      <TextField source="name" />
      <TextField source="phone" />
      <TextField source="address" />
      {/* <StatusDisplay source="status" /> */}
      <ChangeEntityStatus resource="companies" source="status" />
      {permissions?.some((val) =>
        ["SUPER-ADMIN", "COMPANY-ADMIN", "GARAGE-ADMIN"].includes(val)
      ) && <EditButton />}
    </Datagrid>
  </List>
);

// export const CompanyEdit = (props) => {
//   const classes = useStyles();
//   const [vehicleType, setVehicleType] = React.useState(null);

//   React.useEffect(() => {
//     axios
//       .get(`${BASE_URL}/vehicletypes?${stringify({ page: -1, perPage: 10 })}`)
//       .then((data) => {
//         console.log({ data: data?.data });
//         let names = data?.data?.data?.map((each) => ({
//           vehicle_type: each.id,
//         }));
//         setVehicleType(names);
//       });
//   }, []);

//   const [url, setUrl] = React.useState();
//   const handleClick = (files) => {
//     if (!files || !files[0]) {
//       return;
//     }

//     const formData = new FormData();

//     const { token } = JSON.parse(localStorage.getItem("auth"));

//     const config = {
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     };

//     formData.append("file", files[0]);
//     axios.post(`${BASE_URL}/assets`, formData, config).then((data) => {
//       // console.log({data: data?.data?.data})
//       setUrl(data?.data?.data?.file_path);
//     });
//     // create("assets", formData );
//   };

//   const transform = (data) => {
//     const formData = new FormData();
//     for (let key in data) {
//       if (key === "logo_file") {
//         console.log({ data_key: data[key] });
//         formData.append("logo_file", data[key]?.rawFile);
//       } else {
//         if (Array.isArray(data[key]) === true) {
//           data[key]?.forEach((each, i) => {
//             formData.append(key, JSON.stringify(each));
//           });
//         } else {
//           formData.append(key, data[key]);
//         }
//       }
//     }

//     console.log(formData);

//     return formData;
//     // {
//     //   ...data,
//     //   photo: url,
//     // };
//   };

//   console.log({ vehicleType });

//   return (
//     <Edit {...props} transform={transform}>
//       <SimpleForm>
//         <DropzoneArea showAlerts={false} onChange={handleClick} />
//         <ImageInput
//           source="logo_file"
//           label="Related pictures"
//           accept="image/*"
//         >
//           <ImageField source="src" title="title" />
//         </ImageInput>

//         <FormDataConsumer>
//           {({ formData, ...rest }) => {
//             // console.log({logoFile: formData.logo_file})
//             return formData.logo_file ? null : (
//               <ImageField source="logo" title="title" />
//             );
//           }}
//         </FormDataConsumer>

//         <Grid style={{ width: "100%" }} container spacing={3}>
//           <Grid item xs={12} sm={6} md={4}>
//             <TextInput source="name" />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <TextInput source="phone" validate={required()} />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <TextInput source="address" />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <NumberInput source="company_cut" />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <NumberInput source="garage_cut" />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <NumberInput source="provider_cut" />
//           </Grid>
//         </Grid>
//         <ArrayInput
//           source="inspection_prices"
//           validate={required()}
//           // initialValue={vehicleType}
//         >
//           <SimpleFormIterator
//             className={classes.SimpleFormIterator}
//             disableAdd
//             disableReordering={true}
//             disableRemove
//           >
//             {/* <Grid container style={{ width: "100%" }}>
//               <Grid item xs={12} sm={6}> */}
//             <TextInput
//               className={classes.first}
//               label="Vehicle Type"
//               disabled
//               source="vehicle_type"
//             />
//             {/* </Grid>
//               <Grid item xs={12} sm={6}> */}
//             <NumberInput
//               className={classes.second}
//               label="Price"
//               validate={required()}
//               source="price"
//             />
//             {/* </Grid>
//             </Grid> */}
//           </SimpleFormIterator>
//         </ArrayInput>
//       </SimpleForm>
//     </Edit>
//   );
// };

export const CompanyEdit = (props) => {
  const classes = useStyles();
  const [vehicleType, setVehicleType] = React.useState(null);
  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  React.useEffect(() => {
    axios
      .get(`${BASE_URL}/vehicletypes?${stringify({ page: -1, perPage: 10 })}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((data) => {
        console.log({ data: data?.data });
        let names = data?.data?.data?.map((each) => ({
          vehicle_type: each.id,
        }));
        setVehicleType(names);
      });
  }, []);

  const transform = (data) => {
    const formData = new FormData();
    for (let key in data) {
      if (key === "logo_file") {
        console.log({ data_key: data[key] });
        formData.append("logo_file", data[key]?.rawFile);
      } else {
        if (Array.isArray(data[key]) === true) {
          data[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    console.log(formData);
    return formData;
  };

  console.log({ vehicleType });

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          justifyContent="space-around"
          container
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Company logo
                </Typography>
              }
              source="logo_file"
              maxSize={500000}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.logo_file ? null : (
                  <ImageField source="logo" title="Company logo" />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12} sm={12} md={5}></Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          <Grid item xs={12} sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        <Grid
          style={{ width: "100%" }}
          spacing={2}
          justifyContent="space-around"
          container
        >
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="name" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="address" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <NumberInput fullWidth source="company_cut" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <NumberInput fullWidth source="garage_cut" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <NumberInput fullWidth source="provider_cut" />
          </Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          <Grid item xs={12} sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          {vehicleType ? (
            <ArrayInput
              source="inspection_prices"
              validate={required()}
              initialValue={vehicleType}
            >
              <SimpleFormIterator
                className={classes.SimpleFormIterator}
                disableAdd
                disableReordering={true}
                disableRemove
              >
                <FormDataConsumer>
                  {({ getSource, formData, scopedFormData }) => {
                    return (
                      <Grid
                        container
                        spacing={2}
                        style={{ width: "100%", marginTop: 8 }}
                        justifyContent="space-around"
                      >
                        <Grid item xs={12} sm={12} md={5}>
                          <Typography
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              padding: 0,
                            }}
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            Vehicle Type
                          </Typography>
                          <Typography variant="body1" color="textPrimary">
                            {scopedFormData?.vehicle_type}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                          <NumberInput
                            className={classes.second}
                            label="Price"
                            fullWidth
                            validate={required()}
                            source={getSource("price")}
                          />
                        </Grid>
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const CompanyCreate = (props) => {
  const classes = useStyles();
  const [vehicleType, setVehicleType] = React.useState(null);
  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  React.useEffect(() => {
    axios
      .get(`${BASE_URL}/vehicletypes?${stringify({ page: -1, perPage: 10 })}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then((data) => {
        console.log({ data: data?.data });
        let names = data?.data?.data?.map((each) => ({
          vehicle_type: each.id,
        }));
        setVehicleType(names);
      });
  }, []);

  const transform = (data) => {
    const formData = new FormData();
    for (let key in data) {
      if (key === "logo_file") {
        console.log({ data_key: data[key] });
        formData.append("logo_file", data[key]?.rawFile);
      } else {
        if (Array.isArray(data[key]) === true) {
          data[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    console.log(formData);
    return formData;
  };

  console.log({ vehicleType });

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          justifyContent="space-around"
          container
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Company logo
                </Typography>
              }
              source="logo_file"
              maxSize={500000}
              validate={required()}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item xs={12} sm={12} md={5}></Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          <Grid item xs={12} sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        <Grid
          style={{ width: "100%" }}
          spacing={2}
          justifyContent="space-around"
          container
        >
          <Grid item xs={12} sm={12} md={5}>
            <TextInput validate={required()} fullWidth source="name" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextInput validate={required()} fullWidth source="address" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <NumberInput validate={required()} fullWidth source="company_cut" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <NumberInput validate={required()} fullWidth source="garage_cut" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <NumberInput
              validate={required()}
              fullWidth
              source="provider_cut"
            />
          </Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          <Grid item xs={12} sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          {vehicleType ? (
            <ArrayInput
              source="inspection_prices"
              validate={required()}
              initialValue={vehicleType}
            >
              <SimpleFormIterator
                className={classes.SimpleFormIterator}
                disableAdd
                disableReordering={true}
                disableRemove
              >
                <FormDataConsumer>
                  {({ getSource, formData, scopedFormData }) => {
                    return (
                      <Grid
                        container
                        spacing={2}
                        style={{ width: "100%", marginTop: 8 }}
                        justifyContent="space-around"
                      >
                        <Grid item xs={12} sm={12} md={5}>
                          <Typography
                            style={{
                              fontSize: "10px",
                              margin: 0,
                              padding: 0,
                            }}
                            component="span"
                            variant="body2"
                            color="textPrimary"
                          >
                            Vehicle Type
                          </Typography>
                          <Typography variant="body1" color="textPrimary">
                            {scopedFormData?.vehicle_type}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                          <NumberInput
                            label="Price"
                            fullWidth
                            validate={required()}
                            source={getSource("price")}
                          />
                        </Grid>
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </SimpleForm>
    </Create>
  );
};

// export const CompanyCreate = (props) => {
//   const { data, loading, error } = useGetList(
//     "vehicletypes",
//     { page: -1, perPage: 10 },
//     { order: "DESC" }
//   );

//   //   React.useEffect(() => {
//   //     if (data) {
//   //       console.log({ data, error });
//   //     }
//   //   });

//   return (
//     <Create {...props}>
//       <SimpleForm>
//         <ImageInput
//           source="logo_file"
//           label="Related pictures"
//           accept="image/*"
//         >
//           <ImageField source="src" title="title" />
//         </ImageInput>
//         <TextInput source="name" />
//         <TextInput source="phone" validate={required()} />
//         <TextInput source="address" />
//         <NumberInput source="company_cut" />
//         <NumberInput source="garage_cut" />
//         <NumberInput source="provider_cut" />
//         {/* <VehicleType source="vehicle_type" /> */}
//         {data && !loading && (
//           <ArrayInput source="configuration" validate={required()}>
//             <SimpleFormIterator disableReordering={true}>
//               <TextInput label="Configuration Points" validate={required()} source="name" />
//               <BooleanInput label="Configuration Points" validate={required()} source="multi_select" />
//               <ArrayInput source="points" validate={required()}>
//                 <SimpleFormIterator disableReordering={true}>
//                   <TextInput label="Configuration Cases" validate={required()} source="name" />
//                 </SimpleFormIterator>
//               </ArrayInput>
//             </SimpleFormIterator>
//           </ArrayInput>
//         )}
//       </SimpleForm>
//     </Create>
//   );
// };

const useStyles = makeStyles({
  SimpleFormIterator: {
    // display: "flex",
    // direction: "column",
    width: "100%",
    alignItem: "center",
    justifyContent: "center",
    // backgroundColor: "green",
  },
  first: {
    // backgroundColor: "red",
    float: "left",
    clear: "both",
    marginRight: "40px",
    // flex: 1,
  },
  second: {
    // backgroundColor: "yellow",
    // flex: 1,
  },
});
