import { SvgIcon } from "@material-ui/core";

export function OwnerLogo(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="23.518"
      height="19.506"
      viewBox="0 0 23.518 19.506"
    >
      <g id="Owner" transform="translate(2.564 -35.367)">
        <path
          id="Subtraction_3"
          data-name="Subtraction 3"
          d="M18.619,17.524h-1.1A1.1,1.1,0,0,1,16.43,16.43v-1.1H3.286v1.1a1.1,1.1,0,0,1-1.1,1.094H1.1A1.1,1.1,0,0,1,0,16.43V12.2H8.519a1,1,0,0,0,1-1V6.572h8L15.881,1.642H9.519V0h6.362a1.636,1.636,0,0,1,1.555,1.106l2.278,6.561V16.43A1.1,1.1,0,0,1,18.619,17.524ZM15.881,8.762A1.643,1.643,0,1,0,17.524,10.4,1.644,1.644,0,0,0,15.881,8.762Z"
          transform="translate(1.238 37.35)"
        />
        <g
          id="person_black_24dp_1_"
          data-name="person_black_24dp (1)"
          transform="translate(-2.565 35.368)"
        >
          <path
            id="Path_108034"
            data-name="Path 108034"
            d="M10.019,10.019A3.009,3.009,0,1,0,7.009,7.009,3.009,3.009,0,0,0,10.019,10.019Zm0,1.5C8.01,11.523,4,12.532,4,14.533v1.5H16.037v-1.5C16.037,12.532,12.027,11.523,10.019,11.523Z"
            transform="translate(-4 -4)"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
