import * as React from "react";
import { Admin, Resource, defaultTheme } from "react-admin";
import merge from "lodash/merge";
import authProvider from "./authProvider";
import Dashboard from "./Dashboard";
import dataProvider from "./dataProvider";
import PeopleIcon from "@material-ui/icons/People";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import SettingsIcon from "@material-ui/icons/Settings";
import BusinessIcon from "@material-ui/icons/Business";
import ReceiptIcon from "@material-ui/icons/Receipt";
import GradeIcon from "@material-ui/icons/Grade";
import { UserCreate, UserEdit, UserList } from "./pages/Users";
import { CompanyCreate, CompanyEdit, CompanyList } from "./pages/Companies";
import { CssBaseline, Hidden } from "@material-ui/core";
import CustomLayout from "./Layout";
import { GarageCreate, GarageEdit, GarageList } from "./pages/Garages";
import { InvoiceList } from "./pages/Invoices";
import { VehicleTypeCreate, VehicleTypeList } from "./pages/VehicleTypes";
import {
  ConfigurationCreate,
  ConfigurationShow,
  ConfigurationList,
} from "./pages/Configurations";
import { GarageLogo } from "./assets/GarageLogo";
import { InspectionLogo } from "./assets/InspectionLogo";
import { InspectionList, InspectionShow } from "./pages/Inspections";
import {
  DriverCreate,
  DriverEdit,
  DriverList,
  DriverShow,
} from "./pages/Drivers";
import { OwnerCreate, OwnerEdit, OwnerList, OwnerShow } from "./pages/Owners";
import {
  VehicleCreate,
  VehicleEdit,
  VehicleList,
  VehicleShow,
} from "./pages/Vehicles";
import { GradeList } from "./pages/Grades";
import { ActivityLogList } from "./pages/ActivityLog";

const theme = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: "#F88E2C",
    },
    secondary: {
      main: "#1D1A2F",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    success: {
      light: "#81c784",
      main: "#4caf50",
      dark: "#388e3c",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
  },
  typography: {
    body2: {
      lineHeight: 1.8,
    },
    h3: {
      fontWeight: "700",
      fontSize: 20,
    },
  },
  shape: {
    // borderRadius: 8,
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        // backgroundColor: "green",
        maxWidth: 164,
        overflowWrap: "break-word",
        wordWrap: "break-word",
        padding: 12,
      },
      head: {
        borderWidth: 0,
      },
      body: {
        borderWidth: 0,
      },
    },
    MuiSwitch: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        left: 0,
      },
    },
    MuiAlert: {
      root: {
        padding: 0,
        paddingLeft: 6,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
      },
      icon: {
        marginRight: 4,
      },
      message: {
        marginLeft: 2,
        marginRight: 4,
      },
      action: {
        paddingLeft: 0,
        marginRight: 0,
        marginLeft: 0,
      },
    },
    RaMenuItemLink: {
      // color: "#fff",
      // backgroundColor: "#f00",
      root: {
        color: "#fff",
        padding: 8,
        paddingLeft: 24,
        // backgroundColor: "#f00"
      },
      active: {
        color: "#F88E2C",
      },
      icon: {
        color: "inherit",
      },
    },
    // RaSidebar: {
    //   root: { display: "flex" },
    //   drawerPaper: {
    //     backgroundColor: "",
    //     flex: 1,
    //     display: "flex",
    //     flexDirection: "column",
    //     position: "static",
    //     // marginRight: 100,
    //     height: "100vh",
    //   },
    // },
    RaImageInput: {
      root: {},
      dropZone: {
        // maxWidth: "250px",
        // zIndex: 300,
      },
      preview: {
        // position: "absolute",
        // marginTop: "-50px",
        // borderRadius: "50%",
        // backgroundColor: "red",
        // zIndex: 23
      },
      removeButton: {},
    },
    // MuiButton: { // override the styles of all instances of this component
    //     root: { // Name of the rule
    //         color: 'white', // Some CSS
    //     },
    // },
    // RaImageField: {
    //   root: {
    //     borderRadius: 50,
    //     backgroundColor: "#f00"
    //   }
    // }
    MuiGrid: {
      // container: {
      //   backgroundColor: "teal",
      // },
    },
    MuiTextField: {
      root: {
        // width: "272px",
        flex: 1,
      },
    },
    MuiAccordion: {
      root: {
        // padding: 24,
        margin: 16,
      },
    },
    // RaLayout: {
    //   // contentWithSidebar: {
    //   //   backgroundColor: "#1D1A2F"
    //   // },
    // },
    MuiAccordionDetails: {
      root: {
        // backgroundColor: "green",
        paddingLeft: 32,
        marginLeft: 32,
      },
    },
    MuiFormControl: {
      root: {
        display: "block",
      },
    },
  },
});

const App = () => {
  const authorized = (permissions) => {
    console.log({ permissions });
    return [
      <Resource
        name="users"
        icon={PeopleIcon}
        list={UserList}
        {...(permissions?.some((val) =>
          ["SUPER-ADMIN", "GARAGE-ADMIN", "COMPANY-ADMIN"].includes(val)
        )
          ? { edit: UserEdit, create: UserCreate }
          : {})}
      />,
      ...(permissions?.some((val) =>
        [
          "SUPER-ADMIN",
          "SYSTEM-CLERK",
          "COMPANY-ADMIN",
          "COMPANY-CLERK",
        ].includes(val)
      )
        ? [
            <Resource
              name="garages"
              icon={GarageLogo}
              list={GarageList}
              {...(permissions?.some((val) => ["COMPANY-ADMIN"].includes(val))
                ? { edit: GarageEdit, create: GarageCreate }
                : {})}
            />,
          ]
        : []),
      ...(permissions?.some((val) =>
        ["SUPER-ADMIN", "GARAGE-ADMIN", "COMPANY-ADMIN"].includes(val)
      )
        ? [<Resource name="invoices" icon={ReceiptIcon} list={InvoiceList} />]
        : []),
      ...(permissions?.some((val) =>
        ["SUPER-ADMIN", "SYSTEM-CLERK"].includes(val)
      )
        ? [
            <Resource
              name="companies"
              icon={BusinessIcon}
              list={CompanyList}
              {...(permissions?.some((val) => ["SUPER-ADMIN"].includes(val))
                ? { edit: CompanyEdit, create: CompanyCreate }
                : {})}
            />,
          ]
        : []),
      <Resource
        name="vehicletypes"
        icon={DriveEtaIcon}
        list={VehicleTypeList}
        {...(permissions?.some((val) => ["COMPANY-ADMIN"].includes(val))
          ? { create: VehicleTypeCreate }
          : {})}
      />,
      <Resource
        name="configurations"
        icon={SettingsIcon}
        list={ConfigurationList}
        show={ConfigurationShow}
        {...(permissions?.some((val) =>
          ["SUPER-ADMIN", "COMPANY-ADMIN"].includes(val)
        )
          ? { create: ConfigurationCreate }
          : {})}
      />,
      <Resource
        name="inspections"
        icon={InspectionLogo}
        list={InspectionList}
        show={InspectionShow}
      />,
      ...(permissions?.some((val) =>
        [
          "SUPER-ADMIN",
          "SYSTEM-CLERK",
          "COMPANY-ADMIN",
          "COMPANY-CLERK",
          "LEGAL-DOCUMENT-APPROVER",
          "BANK-ACCOUNT-APPROVER",
        ].includes(val)
      )
        ? [
            <Resource
              name="owners"
              icon={InspectionLogo}
              list={OwnerList}
              show={OwnerShow}
              {...(permissions?.some((val) => ["COMPANY-ADMIN"].includes(val))
                ? { edit: OwnerEdit }
                : {})}
              {...(permissions?.some((val) =>
                ["COMPANY-ADMIN", "COMPANY-CLERK"].includes(val)
              )
                ? { create: OwnerCreate }
                : {})}
            />,
          ]
        : []),
      ...(permissions?.some((val) =>
        [
          "SUPER-ADMIN",
          "SYSTEM-CLERK",
          "COMPANY-ADMIN",
          "COMPANY-CLERK",
          "LEGAL-DOCUMENT-APPROVER",
          "BANK-ACCOUNT-APPROVER",
        ].includes(val)
      )
        ? [
            <Resource
              name="drivers"
              icon={InspectionLogo}
              list={DriverList}
              show={DriverShow}
              {...(permissions?.some((val) => ["COMPANY-ADMIN"].includes(val))
                ? { edit: DriverEdit }
                : {})}
              {...(permissions?.some((val) =>
                ["COMPANY-ADMIN", "COMPANY-CLERK"].includes(val)
              )
                ? { create: DriverCreate }
                : {})}
            />,
          ]
        : []),
      ...(permissions?.some((val) =>
        [
          "SUPER-ADMIN",
          "SYSTEM-CLERK",
          "COMPANY-ADMIN",
          "COMPANY-CLERK",
          "LEGAL-DOCUMENT-APPROVER",
          "BANK-ACCOUNT-APPROVER",
        ].includes(val)
      )
        ? [
            <Resource
              name="vehicles"
              icon={InspectionLogo}
              list={VehicleList}
              show={VehicleShow}
              {...(permissions?.some((val) => ["COMPANY-ADMIN"].includes(val))
                ? { edit: VehicleEdit }
                : {})}
              {...(permissions?.some((val) =>
                ["COMPANY-ADMIN", "COMPANY-CLERK"].includes(val)
              )
                ? { create: VehicleCreate }
                : {})}
            />,
            ...(permissions?.some((val) =>
              ["SUPER-ADMIN", "COMPANY-ADMIN"].includes(val)
            )
              ? [
                  <Resource
                    name="activities"
                    icon={InspectionLogo}
                    list={ActivityLogList}
                  />,
                ]
              : []),
          ]
        : []),
      <Resource name="grades" icon={GradeIcon} list={GradeList} />,
    ];
  };

  return (
    <>
      <CssBaseline />
      <Admin
        theme={theme}
        layout={CustomLayout}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
      >
        {authorized}
      </Admin>
    </>
  );
};

export default App;
