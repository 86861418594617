import axios from "axios";
import { stringify } from "query-string";
import { BASE_URL } from "./config";

const dataProvider = {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      //   range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      // sort: order,
      page,
      per_page: perPage,
      filter: JSON.stringify(params.filter),
    };

    const url = `${BASE_URL}/${resource}?${stringify(query)}`;

    const { access_token } = JSON.parse(localStorage.getItem("auth"));

    return axios({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then(({ data }) => {
        console.log({ data: data?.data });
        return {
          data: data?.data,
          total: data?.meta_data?.total_count,
        };
        // return {
        //   data: data?.data.map((each) => {
        //     return { ...each, id: each?.name };
        //   }),
        //   total: data?.data?.length,
        // };
      })
      .catch((err) => {
        console.log({ err });
        throw new Error(err?.response?.data?.errors?.title || "Network error");
      });
  },

  getOne: (resource, params) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));
    const url = `${BASE_URL}/${resource}/${params.id}`;

    return axios({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then(({ data }) => ({
        data: data?.data,
      }))
      .catch((err) => {
        console.log({ err });
        throw new Error(err?.response?.data?.errors?.title || "Network error");
      });
  },

  getMany: (resource, params) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${BASE_URL}/${resource}?${stringify(query)}`;

    return axios({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then(({ data }) => ({
        data: data?.data,
      }))
      .catch((err) => {
        console.log({ err });
        throw new Error(err?.response?.data?.errors?.title || "Network error");
      });
    // return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${BASE_URL}/${resource}?${stringify(query)}`;

    return axios({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then(({ data }) => ({
        data: data?.data,
        total: data?.meta_data?.total_count,
      }))
      .catch((err) => {
        console.log({ err });
        throw new Error(err?.response?.data?.errors?.title || "Network error");
      });

    // return httpClient(url).then(({ headers, json }) => ({
    //   data: json,
    //   total: parseInt(headers.get("content-range").split("/").pop(), 10),
    // }));
  },

  update: (resource, params) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));
    const url = `${BASE_URL}/${resource}/${params.id}`;

    // console.log({params})

    return axios({
      method: "patch",
      url,
      data: params.data,
      headers: {
        Authorization: `Bearer ${access_token}`,
        // "Content-Type": "application/json",
        // Accept: "application/json",
      },
    })
      .then(({ data }) => {
        console.log({ data: data?.data });
        return {
          data: data?.data,
        };
      })
      .catch((err) => {
        console.log({ err });
        throw new Error(err?.response?.data?.errors?.title || "Network error");
      });
  },

  updateMany: (resource, params) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${BASE_URL}/${resource}?${stringify(query)}`;

    return axios({
      method: "put",
      url,
      data: JSON.stringify(params.data),
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(({ data }) => ({
        data: data?.data,
      }))
      .catch((err) => {
        console.log({ err });
        throw new Error(err?.response?.data?.errors?.title || "Network error");
      });
  },

  create: (resource, params) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));
    const url = `${BASE_URL}/${resource}`;

    return axios({
      method: "post",
      url,
      data: params.data,
      headers: {
        Authorization: `Bearer ${access_token}`,
        // "Content-Type": "application/json",
        // Accept: "application/json",
      },
    })
      .then(({ data }) => ({
        data: data?.data,
        // data: { ...params.data, id: data?.data?.id },
      }))
      .catch((err) => {
        console.log({ err });
        throw new Error(err?.response?.data?.errors?.title || "Network error");
      });
  },

  delete: (resource, params) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));
    const url = `${BASE_URL}/${resource}/${params.id}`;

    return axios({
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then(({ data }) => ({
        data: data?.data,
      }))
      .catch((err) => {
        console.log({ err });
        throw new Error(err?.response?.data?.errors?.title || "Network error");
      });
  },

  deleteMany: (resource, params) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${BASE_URL}/${resource}/${stringify(query)}`;

    return axios({
      method: "delete",
      url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
      .then(({ data }) => ({
        data: data?.data,
      }))
      .catch((err) => {
        console.log({ err });
        throw new Error(err?.response?.data?.errors?.title || "Network error");
      });
  },
};

export default dataProvider;
