import { SvgIcon } from "@material-ui/core";

export function GarageLogo(props) {
    return (
      <SvgIcon
        {...props}
        id="circle_add"
        data-name="circle add"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path
          id="Path_107620"
          data-name="Path 107620"
          d="M0,0H24V24H0Z"
          fill="none"
        />
        <path
          id="Path_107632"
          data-name="Path 107632"
          d="M298.422,219.782a.924.924,0,0,0-.692-.311l.408-.018-5.124.231v.011l-.122-.005-.138.006v-.012l-5.124-.231.408.018a.924.924,0,0,0-.691.31,11.954,11.954,0,0,0-1.861,3.227l7.4.4.711-.038.133.213.144.23.359.572.359.572.359.572.359.572.135.216-.393.742-.632,1.193-.1.187-.212.008-1.349.048-.839.031-.136-.216-.358-.572-.359-.572-.359-.572-.359-.572-.358-.572-.033-.052c-.014.033-.028.066-.041.1a3.711,3.711,0,0,0-.24.947,3.669,3.669,0,0,0-.006.8,3.651,3.651,0,0,0,.406,1.34q.063.121.138.239a3.762,3.762,0,0,0,.23.327,3.65,3.65,0,0,0,1.073.922,3.7,3.7,0,0,0,1.711.478l.067,0h.115q.088,0,.176-.008l.037,0,.011-.007a14.376,14.376,0,0,0,1.245,1.7c-.591.03-1.227.047-1.869.05h-.26c-2.182-.012-4.3-.177-4.767-.494a2.572,2.572,0,0,1-2.346.519v1.062a.227.227,0,0,1-.224.228h-2.434a.227.227,0,0,1-.224-.228V225.92a.922.922,0,0,1,.15-.505c.446-.515,1.749-1.975,2-2.282-3.415-.081-1.894-2.174.525-1.479a14.822,14.822,0,0,1,1.7-2.752.923.923,0,0,1,.662-.279h.3a2.81,2.81,0,0,1,1.245-.366,14.49,14.49,0,0,1,3.577-.287,14.316,14.316,0,0,1,3.519.287,2.809,2.809,0,0,1,1.245.366h.293a.932.932,0,0,1,.661.273,14.831,14.831,0,0,1,1.7,2.758c2.42-.7,3.941,1.4.525,1.479.253.306,1.555,1.767,2,2.282a.921.921,0,0,1,.15.506v5.7a1.3,1.3,0,0,1-1.3,1.3c-1.828,0-4.3-2.848-5.168-4.226h0l0,0h0c.029-.051.057-.1.084-.155.017-.034.034-.068.05-.1s.019-.04.028-.06a3.7,3.7,0,0,0,.316-1.748,3.65,3.65,0,0,0-.12-.722,3.694,3.694,0,0,0-.436-.995c-.049-.079-.1-.155-.155-.228a3.665,3.665,0,0,0-1.028-.95,3.7,3.7,0,0,0-.723-.344q-.1-.034-.194-.062l5.924-.32a11.958,11.958,0,0,0-1.86-3.226ZM287.3,218.691l.053-.068Z"
          transform="translate(-280.885 -213.781)"
          fill-rule="evenodd"
        />
      </SvgIcon>
    );
  }