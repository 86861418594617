import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleForm,
  TextInput,
  Create,
  NumberField,
  SimpleFormIterator,
  ArrayInput,
  required,
  BooleanInput,
  ShowButton,
  DateField,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ArrayField,
  SimpleShowLayout,
  useListContext,
  FormDataConsumer,
} from "react-admin";
import { ChangeConfigStatus, ChangeStatus } from "../components/CustomFields";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const ConfigurationList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    sort={{ field: "created_at", order: "DESC" }}
    {...props}
  >
    <Datagrid size="medium" padding="normal">
      <NumberField source="version" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <ChangeConfigStatus resource="configurations" label="" source="status" />
      <TextField source="vehicle_type" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const ConfigurationShow = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const PointsGrid = () => {
    const { ids, data } = useListContext();
    return ids && ids?.length > 0 ? (
      <Box>
        {ids?.map((id) => (
          <Accordion
            key={id}
            style={{ transition: "none" }}
            TransitionProps={{
              timeout: 0,
              unmountOnExit: true,
            }}
            expanded={expanded === id}
            onChange={handleChange(id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container spacing={4} style={{ width: "100%" }}>
                <Grid xs={4} item>
                  <Box flex={1}>
                    <Typography
                      style={{ fontSize: "10px", margin: 0, padding: 0 }}
                      variant="body2"
                      color="textPrimary"
                    >
                      Point Name
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {data[id].name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box flex={1}>
                    <Typography
                      style={{ fontSize: "10px", margin: 0, padding: 0 }}
                      variant="body2"
                      color="textPrimary"
                    >
                      Selection
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {data[id]?.multi_select
                        ? "Multiple Select"
                        : "Single Select"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box flex={1}>
                    <Typography
                      style={{ fontSize: "10px", margin: 0, padding: 0 }}
                      variant="body2"
                      color="textPrimary"
                    >
                      Number of Cases
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {data[id]?.cases?.length}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <ArrayField label="Cases" record={data[id]} source="cases">
                <CasesGrid />
              </ArrayField>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    ) : (
      <></>
    );
  };

  const CasesGrid = () => {
    const { ids, data } = useListContext();
    return ids && ids?.length > 0 ? (
      <Box mx={2} flex={1}>
        {ids?.map((id) => (
          <Grid key={id} container spacing={4} style={{ width: "100%" }}>
            <Grid xs={7} item>
              <Box flex={1}>
                <Typography
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    padding: 0,
                  }}
                  variant="body2"
                  color="textPrimary"
                >
                  Case Name
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {data[id]?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={5} item>
              <Box ml={4} flex={1}>
                <Typography
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    padding: 0,
                  }}
                  variant="body2"
                  color="textPrimary"
                >
                  Score
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {data[id]?.score}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    ) : (
      <></>
    );
  };

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="vehicle_type" />
        <ArrayField source="points">
          <PointsGrid />
        </ArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export const ConfigurationCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput source="vehicle_type" reference="vehicletypes">
          <SelectInput fullWidth validate={required()} optionText="id" />
        </ReferenceInput>
        <ArrayInput source="points" validate={required()}>
          <SimpleFormIterator disableReordering={true}>
            <FormDataConsumer>
              {({ formData, getSource, scopedFormData, ...rest }) => (
                <>
                  <Grid
                    style={{ width: "100%" }}
                    justifyContent="space-around"
                    container
                    spacing={2}
                  >
                    <Grid item sm={12} md={5}>
                      <TextInput
                        label="Configuration Points"
                        validate={required()}
                        source={getSource("name")}
                        record={scopedFormData}
                      />
                    </Grid>
                    <Grid item sm={12} md={5}>
                      <BooleanInput
                        label="Multi Select"
                        source={getSource("multi_select")}
                        record={scopedFormData}
                      />
                    </Grid>
                  </Grid>
                  <ArrayInput
                    label="Cases"
                    source={getSource("cases")}
                    record={scopedFormData}
                    validate={required()}
                  >
                    <SimpleFormIterator disableReordering={true}>
                      <FormDataConsumer>
                        {({ formData, getSource, scopedFormData, ...rest }) => (
                          <Grid
                            style={{ width: "100%" }}
                            justifyContent="space-around"
                            container
                            spacing={2}
                          >
                            <Grid item sm={12} md={5}>
                              <TextInput
                                label="Configuration Cases"
                                validate={required()}
                                source={getSource("name")}
                                record={scopedFormData}
                              />
                            </Grid>
                            <Grid item sm={12} md={5}>
                              <NumberInput
                                label="Configuration Score"
                                validate={required()}
                                source={getSource("score")}
                                record={scopedFormData}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};
