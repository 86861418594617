import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  DateField,
  useShowController,
  useNotify,
  useRedirect,
  useRefresh,
  FunctionField,
  TextInput,
  Show,
  SimpleShowLayout,
} from "react-admin";

// material-ui
import {
  Card,
  CardContent,
  makeStyles,
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as MuiTextField,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

import { useReactToPrint } from "react-to-print";
import { ChangeStatus } from "../components/CustomFields";

import moment from "moment";
import axios from "axios";
import { BASE_URL } from "../config";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import PrintIcon from "@material-ui/icons/Print";
import WarningIcon from "@material-ui/icons/Warning";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  invoiceHeader: {
    backgroundColor: theme.palette.grey[300],
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    padding: 16,
  },
}));

export const InvoiceList = (props) => {
  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={[<TextInput fullWidth label="Search" source="q" alwaysOn />]}
      // pagination={<Pagination limit={<div>No Results Found</div>} />}
      // pagination={false}
      sort={{ field: "created_at", order: "DESC" }}
      empty={false}
    >
      <Datagrid
        rowClick="expand"
        expand={<InvoiceShow permissions={props?.permissions} />}
      >
        <TextField source="number" />
        <DateField source="created_at" />
        <NumberField source="total" />
        {/* <ChangeStatus resource="invoices" source="status" /> */}
        <FunctionField
          label="Status"
          sortBy="status"
          render={(record) => (
            <Alert
              severity={
                record?.status === "PENDING"
                  ? "info"
                  : record?.status === "APPROVED"
                  ? "success"
                  : record?.status === "OVERDUE"
                  ? "warning"
                  : "error"
              }
              // {...(record?.status === "PENDING" ? { icon: <HourglassEmptyIcon /> } : {})}
              style={{ width: 140 }}
            >
              <Typography variant="body1">{record?.status}</Typography>
            </Alert>
          )}
        />
      </Datagrid>
    </List>
  );
};

export const InvoiceShow = (props) => {
  const { record } = useShowController(props);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState();

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const printRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  if (!record) {
    return;
  }

  const handleClick = (operator, id, reason) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));

    return axios({
      method: "patch",
      url: `${BASE_URL}/invoices/${id}/${operator}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: {
        reason,
      },
    })
      .then(({ data }) => {
        console.log({ data: data?.data });
        // notify(data?.data);
        redirect("/invoices");
        refresh();
      })
      .catch((err) => {
        console.log({ err });
        notify(err?.response?.data?.errors?.title || "Network error", {
          type: "warning",
        });
      });
  };
  // Bill Info component
  const BillTo = ({ name, phone, address }) => {
    return (
      <>
        <Typography variant="body1" color="textSecondary">
          {name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {phone}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {address}
        </Typography>
      </>
    );
  };

  // invoice table
  function InvocieTable() {
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item</TableCell>
              <TableCell>Month</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Inspection</TableCell>
              <TableCell>
                {moment(record?.from).format("MMM Do YYYY")} to{" "}
                {moment(record?.to).format("MMM Do YYYY")}
              </TableCell>
              <TableCell>{`${record?.total} ETB`}</TableCell>
              <TableCell>{`${record?.total} ETB`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}></TableCell>
              <TableCell>Sub Total</TableCell>
              <TableCell>{`${record?.total} ETB`}</TableCell>
              <TableCell>{`${record?.total} ETB`}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tax</TableCell>
              <TableCell>{`${record?.tax} %`}</TableCell>
              <TableCell>{record?.tax} %</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2}></TableCell>
              <TableCell rowSpan={2}></TableCell>
              <TableCell>
                <Typography variant="h3">Total</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h3">{`${record?.total} ETB`}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const onFailure = (error) => {
    notify(`Could not load post: ${error.message}`);
    redirect("/invoices");
    refresh();
  };

  return (
    <Show onFailure={onFailure} {...props}>
      <SimpleShowLayout>
        <Card className={classes.root} ref={printRef}>
          <Box
            p={1}
            pt={3}
            ml={2}
            mr={1}
            display="flex"
            width="100%"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              // bgcolor="red"
              margin="auto"
              width="70%"
              paddingRight={3}
              paddingLeft={3}
              justifyContent="flex-end"
            >
              <Box
                color={
                  record?.status === "APPROVED"
                    ? "success.main"
                    : record?.status === "REJECTED"
                    ? "error.main"
                    : record?.status === "OVERDUE"
                    ? "primary.main"
                    : "secondary.main"
                }
                pl={1}
                pr={1}
                mx={1}
                bgcolor={
                  record?.status === "APPROVED"
                    ? "#D5FFDC"
                    : record?.status === "REJECTED"
                    ? "#FFDBDB"
                    : record?.status === "OVERDUE"
                    ? "#FFF8DB"
                    : "#E8F4FD"
                }
              >
                <Button
                  color="inherit"
                  startIcon={
                    record?.status === "APPROVED" ? (
                      <ThumbUpIcon color="inherit" />
                    ) : record?.status === "REJECTED" ? (
                      <ThumbDownIcon color="inherit" />
                    ) : record?.status === "PENDING" ? (
                      <HourglassEmptyIcon color="inherit" />
                    ) : (
                      <WarningIcon color="inherit" />
                    )
                  }
                >
                  {record?.status}
                </Button>
              </Box>
              <Box ml={1}>
                <Button
                  color="secondary"
                  startIcon={<PrintIcon />}
                  variant="contained"
                  onClick={handlePrint}
                >
                  Print
                </Button>
              </Box>
            </Box>
          </Box>
          <CardContent>
            <Box
              style={{
                backgroundColor: "#fff",
                borderRadius: 10,
                width: "70%",
                margin: "auto",
                minWidth: 700,
              }}
            >
              {/* Invoice Header */}
              <Box
                display={"flex"}
                style={{
                  backgroundColor: "#e2e2e2",
                  borderTopRightRadius: 10,
                  borderTopLeftRadius: 10,
                }}
              >
                <Box style={{ flexGrow: 1 }} p={4}>
                  <Typography variant="h3" gutterBottom>
                    Invoice
                  </Typography>
                  <Typography color="textSecondary">
                    {record?.number}
                  </Typography>
                </Box>
                <Box p={4}>
                  <Typography variant="h3" gutterBottom>
                    DATE
                  </Typography>
                  <Typography color="textSecondary">
                    {moment(record?.created_at).format("MMM Do YYYY")}
                  </Typography>
                </Box>
              </Box>

              {/* Payment terms  */}
              <Box pt={2} pr={2} textAlign={"right"}>
                <Typography gutterBottom variant="body2" color="textSecondary">
                  Payment terms (due on receipt, from{" "}
                  {moment(record?.to).format("MMMM Do YYYY")} to{" "}
                  {moment(record?.to).format("MMMM Do YYYY")})
                </Typography>
              </Box>

              <Box p={4}>
                <Grid container spacing={5}>
                  {/* invoice From  */}
                  <Grid item xs={6}>
                    <Typography variant="h3">FROM</Typography>
                    {record?.type === "COMPANY_TO_GARAGE" ? (
                      <BillTo
                        name={record?.company?.name}
                        phone={record?.company?.phone}
                        address={record?.company?.address}
                      />
                    ) : (
                      <BillTo
                        name={record?.provider?.name}
                        phone={record?.provider?.phone}
                        address={record?.provider?.address}
                      />
                    )}
                  </Grid>

                  {/* invoice to  */}
                  <Grid item xs={6}>
                    <Typography variant="h3">BILLED TO</Typography>
                    {record?.type === "COMPANY_TO_GARAGE" ? (
                      <BillTo
                        name={record?.garage?.name}
                        phone={record?.garage?.phone}
                        address={record?.garage?.address}
                      />
                    ) : (
                      <BillTo
                        name={record?.company?.name}
                        phone={record?.company?.phone}
                        address={record?.company?.address}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>

              {/* Invocie Table */}
              <Box p={2}>
                <InvocieTable />
              </Box>

              {/* Description */}
              <Box pt={2} />
              <Box p={3} marginBottom={3}>
                <Typography>Description</Typography>
                <Typography variant="caption" color="textSecondary">
                  {record?.description}
                </Typography>
              </Box>
              <Box p={1}>
                {(record?.status === "PENDING" ||
                  record?.status === "OVERDUE") &&
                  ((record?.type === "COMPANY_TO_GARAGE" &&
                    props?.permissions?.some((val) =>
                      ["COMPANY-ADMIN"].includes(val)
                    )) ||
                    (record?.type === "PROVIDER_TO_COMPANY" &&
                      props?.permissions?.some((val) =>
                        ["SUPER-ADMIN"].includes(val)
                      ))) && (
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignSelf="center"
                    >
                      <Box color="success.main">
                        <Button
                          color="inherit"
                          startIcon={<ThumbUpIcon color="inherit" />}
                          onClick={() => {
                            setOpen("approve");
                          }}
                        >
                          Approve
                        </Button>
                      </Box>
                      <Box px={4} />
                      <Box color="error.main">
                        <Button
                          color="inherit"
                          startIcon={<ThumbDownIcon color="inherit" />}
                          onClick={() => {
                            setOpen("reject");
                          }}
                        >
                          Reject
                        </Button>
                      </Box>
                      <Dialog
                        open={!!open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="form-dialog-title"
                      >
                        <DialogTitle id="form-dialog-title">Reason</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            To approve inspection you need to provide a reason!
                          </DialogContentText>
                          <Box p={2} />
                          <MuiTextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            label="Reason"
                            fullWidth
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => setOpen(false)}
                            color="primary"
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              if (reason) {
                                handleClick(open, record?.id, reason);
                                setOpen(false);
                                setReason();
                              }
                            }}
                            color="primary"
                          >
                            Send
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </SimpleShowLayout>
    </Show>
  );
};
