import SvgIcon from "@material-ui/core/SvgIcon";

export default function AutomatrixLogo(props) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="413.209"
      height="298.544"
      viewBox="0 0 413.209 298.544"
      {...props}
    >
      <g id="automatrix" transform="translate(-5.883)">
        <g id="Group_198" data-name="Group 198" transform="translate(79.715 0)">
          <path
            id="Path_2530"
            data-name="Path 2530"
            d="M681.3,538c-1.609,0-3.228.027-4.827.184-10.173,1-18.626,8.062-20.354,16.964-.881,4.531.054,5.708,4.58,5.708q33.09.007,66.184,0V538Q704.093,538,681.3,538Z"
            transform="translate(-604.247 -407.966)"
            fill="#1d1a2f"
          />
          <path
            id="Path_2531"
            data-name="Path 2531"
            d="M667.439,520.871c-3.854-.009-7.732-.9-11.54-1.615a4.977,4.977,0,0,1-2.985-1.934c-.98-1.579-2.315-2.653-3.924-1.465-.913.671-1.386,3.006-.751,3.791a10.868,10.868,0,0,0,5.361,3.863,70.764,70.764,0,0,0,15.049,2.145c23.155.2,46.312.106,69.468.1v-4.8Q702.779,520.943,667.439,520.871Z"
            transform="translate(-615.481 -440.277)"
            fill="#ff8e02"
          />
          <path
            id="Path_2532"
            data-name="Path 2532"
            d="M683.687,599.383c-4.526,0-5.46-1.177-4.58-5.708,1.728-8.9,10.18-15.966,20.354-16.964,1.6-.158,3.218-.184,4.827-.184q22.791,0,45.583,0v-43c-23.157.01-46.314.121-69.469-.112a62.22,62.22,0,0,1-15.048-2.466c-2.075-.551-4.053-2.582-5.361-4.441-.636-.9-.162-3.587.75-4.359,1.609-1.366,2.944-.131,3.924,1.684a4.981,4.981,0,0,0,2.985,2.223c3.808.825,7.686,1.847,11.539,1.857q35.34.087,70.68.09V510.993q-38.765.01-77.53.017a35.394,35.394,0,0,0-5.419.291c-15.816,2.453-26.7,15.245-27.03,32.16-.238,12.166-.254,24.346.029,36.51.418,17.908,12.836,29.755,30.753,29.755q39.6.008,79.2,0V599.386Q716.778,599.386,683.687,599.383Zm-10.719-33.616a36.281,36.281,0,0,1-23.613-11.146c-5.664-5.866-3.93-16.658,3.22-20.6a6.968,6.968,0,0,1,5.371-.18,35.267,35.267,0,0,1,8.492,5.031c4.817,3.932,9.423,8.14,13.913,12.445,2.541,2.437,5.184,5.356,3.781,9.21-1.415,3.892-5.273,4.555-8.933,5.065C674.5,565.684,673.8,565.7,672.968,565.766Z"
            transform="translate(-627.234 -446.496)"
            fill="#ff8e02"
          />
          <path
            id="Path_2533"
            data-name="Path 2533"
            d="M697.062,484.418a27.645,27.645,0,0,0-4.81.282c-11.44,2.029-18.99,9.142-24.271,18.985-6.274,11.69-12.381,23.47-18.556,35.214-2.208,4.2-1.735,4.953,3.092,4.953q42.649,0,85.3,0V484.473C724.231,484.457,710.645,484.408,697.062,484.418Z"
            transform="translate(-615.178 -484.416)"
            fill="#ff8e02"
          />
          <path
            id="Path_2534"
            data-name="Path 2534"
            d="M641.972,550.095c0,6.436-.1,12.219.068,18a12.727,12.727,0,0,0,1.437,4.548,5.852,5.852,0,0,0,5.948,3.72q8.733-.157,17.471,0a6.027,6.027,0,0,0,5.992-3.691c1.985-4.021,1.917-8.276,1.582-13.035C662.554,560.513,651.32,559.368,641.972,550.095Z"
            transform="translate(-624.05 -390.704)"
            fill="#ff8e02"
          />
          <path
            id="Path_2535"
            data-name="Path 2535"
            d="M656.5,517.123a6.324,6.324,0,0,0,4.762-5.963,6.121,6.121,0,0,0-4.408-6.193,26.426,26.426,0,0,0-17.924,0,6.295,6.295,0,0,0,.184,12.037,63.843,63.843,0,0,0,8.421,1.657C650.648,518.164,653.688,518,656.5,517.123Z"
            transform="translate(-634.57 -457.325)"
            fill="#ff8e02"
          />
          <path
            id="Path_2536"
            data-name="Path 2536"
            d="M727.473,495.994c5.42,0,10.838.112,16.253-.041,3.305-.093,5,1.087,4.737,4.533a9.176,9.176,0,0,0,.018.927c.214,8.188.214,8.227-7.9,8.278-9.6.061-19.2-.117-28.787.131-4.34.112-5.961-1.561-5.4-5.718a8.717,8.717,0,0,0,.019-2.318c-.616-4.465,1.269-6.106,5.745-5.837,5.091.306,10.212.071,15.32.071Z"
            transform="translate(-532.248 -467.999)"
            fill="#ff8e02"
          />
          <path
            id="Path_2537"
            data-name="Path 2537"
            d="M711.076,513.327h52.381C747.694,518.508,716.854,518.681,711.076,513.327Z"
            transform="translate(-525.408 -443.166)"
            fill="#ff8e02"
          />
          <path
            id="Path_2538"
            data-name="Path 2538"
            d="M721.458,533.808a9.126,9.126,0,0,1-1.2,2.626c-3.756,3.861-7.554,7.683-11.464,11.389-.978.93-2.388,1.572-3.562-.083-2.311-3.254-4.682-6.467-6.961-9.744-.621-.893-1.1-2.031-.022-2.922,1.26-1.041,2.114-.211,3.121.718,6.676,6.16,6.7,6.131,13.688-.465,1.009-.951,2.06-1.856,3.121-2.75.58-.49,1.281-1.138,2.022-.6A6.653,6.653,0,0,1,721.458,533.808Z"
            transform="translate(-544.617 -416.846)"
            fill="#ff8e02"
          />
          <path
            id="Path_2539"
            data-name="Path 2539"
            d="M721.22,544.838a4.045,4.045,0,0,1-.534,1.456,64.455,64.455,0,0,1-12.238,11.862,2.911,2.911,0,0,1-1.784.282c-2.165-.2-9.222-9.489-9.013-11.714.157-1.672,1.313-1.74,2.3-1.2a10.74,10.74,0,0,1,2.7,2.471c4.929,5.669,5.157,5.684,10.593.235q2.454-2.457,4.943-4.875c.546-.532,1.126-1.151,2-.753C721,542.972,721.157,543.747,721.22,544.838Z"
            transform="translate(-544.571 -401.574)"
            fill="#ff8e02"
          />
          <path
            id="Path_2540"
            data-name="Path 2540"
            d="M719.778,520.359a3.467,3.467,0,0,1,.131,3.992c-2.539,4.749-6.548,8.137-10.571,11.486-1.592,1.325-3.456,1.463-4.88-.378-2.075-2.682-4.072-5.422-6.035-8.189-.726-1.023-1.323-2.344-.085-3.3.973-.747,2.067-.044,2.966.621a9.647,9.647,0,0,1,1.06.9c5.385,5.339,5.371,5.327,10.873.442C715.293,524.1,717.424,522.361,719.778,520.359Z"
            transform="translate(-544.604 -433.132)"
            fill="#ff8e02"
          />
          <path
            id="Path_2541"
            data-name="Path 2541"
            d="M712.052,525.363a205.83,205.83,0,0,1,48.049-.245A165.242,165.242,0,0,1,712.052,525.363Z"
            transform="translate(-524.016 -428.181)"
            fill="#ff8e02"
          />
          <path
            id="Path_2542"
            data-name="Path 2542"
            d="M706.682,523.592c-3.334-.367-4.082-3.318-5.562-5.312-1.272-1.714-4.286-4.213-2.41-5.718,1.917-1.541,3.424,2.08,4.834,3.607,2.22,2.4,3.975,2.987,6.443.308A71.809,71.809,0,0,1,717.179,510a2.217,2.217,0,0,1,2.977-.291,1.5,1.5,0,0,1,.019,2.425q-5.664,5.438-11.464,10.734A5.547,5.547,0,0,1,706.682,523.592Z"
            transform="translate(-543.907 -448.994)"
            fill="#ff8e02"
          />
          <path
            id="Path_2543"
            data-name="Path 2543"
            d="M713.13,535.778a267.323,267.323,0,0,1,43.392-.012A209.1,209.1,0,0,1,713.13,535.778Z"
            transform="translate(-522.478 -412.399)"
            fill="#ff8e02"
          />
          <path
            id="Path_2544"
            data-name="Path 2544"
            d="M712.474,547.173c.609-2.014,2.306-1.79,3.793-1.793q19.257-.033,38.511,0a13.66,13.66,0,0,1,2.311.18c.752.131,1.555.337,1.607,1.335.058,1.15-.91,1.041-1.59,1.177a9.221,9.221,0,0,1-1.852.09q-19.719,0-39.438-.037C714.672,548.119,713.338,548.435,712.474,547.173Z"
            transform="translate(-523.413 -397.451)"
            fill="#ff8e02"
          />
          <path
            id="Path_2545"
            data-name="Path 2545"
            d="M830.476,495.944c-.012-6.608-.5-9.821-3.074-11.365H685.043V665.463c.893,4.616,3.64,6.2,9.984,6.181,42.744-.146,85.492.764,128.229-.733,4.419-.152,6.356-2.337,6.974-6.21a39.37,39.37,0,0,0,.306-6.176q.026-40.763.012-81.529H830.5C830.5,549.977,830.534,522.962,830.476,495.944Zm-15.913,81.591q-.007,36.009-.009,72.016c0,5.722-.325,5.9-5.962,5.446-3.318-.265-6.669-.163-10.006-.165q-45.791-.01-91.579,0c-6.49,0-6.664-.233-6.661-6.732q.036-70.588.051-141.174c0-7.651.061-7.683,7.744-7.683,33.546,0,67.092.077,100.639-.114,4.64-.027,5.909,1.4,5.875,5.916C814.464,529.2,814.563,553.37,814.563,577.534Z"
            transform="translate(-562.553 -484.184)"
            fill="#ff8e02"
          />
        </g>
        <g
          id="Group_204"
          data-name="Group 204"
          transform="translate(6.854 252.836)"
        >
          <path
            id="Path_2546_-_Outline"
            data-name="Path 2546 - Outline"
            d="M637.118,657.71h-7.9V646.317H616.042V657.71h-7.9V646.317h-6.9l5.2-7.291h1.7V625.021a10.988,10.988,0,0,1,2.051-6.373,13.88,13.88,0,0,1,5.346-4.512,16.22,16.22,0,0,1,14.189,0,13.85,13.85,0,0,1,5.344,4.512,11,11,0,0,1,2.051,6.372Zm-21.076-18.684h13.172V625.021a4.218,4.218,0,0,0-.837-2.6,6,6,0,0,0-2.418-1.915,8.069,8.069,0,0,0-3.33-.711,6.835,6.835,0,0,0-4.844,1.667,4.708,4.708,0,0,0-1.741,3.558Z"
            transform="translate(-601.238 -612.503)"
            fill="#fff"
            stroke="#fff"
            stroke-width="1"
          />
          <path
            id="Path_2547_-_Outline"
            data-name="Path 2547 - Outline"
            d="M618.192,612.979h7.9v31.745a4.683,4.683,0,0,0,1.775,3.508,6.816,6.816,0,0,0,4.814,1.69,7.523,7.523,0,0,0,4.762-1.591,4.267,4.267,0,0,0,1.822-3.606V612.979h7.9v31.745a10.944,10.944,0,0,1-2.052,6.354,13.856,13.856,0,0,1-5.346,4.5,16.3,16.3,0,0,1-14.181,0,13.856,13.856,0,0,1-5.346-4.5,10.934,10.934,0,0,1-2.053-6.355Z"
            transform="translate(-574.959 -611.992)"
            fill="#fff"
            stroke="#fff"
            stroke-width="1"
          />
          <path
            id="Path_2548_-_Outline"
            data-name="Path 2548 - Outline"
            d="M631.751,612.979h26.935v7.279h-9.517V657.2h-7.9V620.258h-9.514Z"
            transform="translate(-552.186 -611.992)"
            fill="#fff"
            stroke="#fff"
            stroke-width="1"
          />
          <g
            id="Group_199"
            data-name="Group 199"
            transform="translate(113.852 0.174)"
          >
            <path
              id="Path_2549"
              data-name="Path 2549"
              d="M684.118,628.892l1.52-3.312-2.172-3.76-3.521-.332q-.563-.63-1.175-1.2l-.343-3.637-3.765-2.168-3.209,1.468c-.532-.175-1.08-.329-1.63-.457l-2.111-2.972h-4.344l-2.045,2.88c-.552.114-1.1.256-1.641.415l-3.314-1.514-3.758,2.171-.334,3.521c-.413.375-.819.766-1.2,1.177l-3.638.34-2.173,3.765,1.466,3.209c-.173.535-.323,1.079-.455,1.63l-2.966,2.11v4.343l2.875,2.047c.114.551.254,1.1.42,1.639l-1.518,3.316,2.173,3.758,3.523.334q.563.623,1.172,1.2l.346,3.637,3.758,2.173,3.21-1.466c.533.173,1.079.327,1.632.455l2.109,2.967h4.348l2.042-2.875c.555-.119,1.1-.256,1.641-.42l3.312,1.518,3.76-2.174.334-3.522c.418-.373.819-.766,1.2-1.172l3.639-.346,2.168-3.765-1.467-3.205c.174-.536.328-1.077.456-1.629l2.972-2.11v-4.348l-2.88-2.042C684.424,629.978,684.282,629.431,684.118,628.892ZM665.4,651.869a17.251,17.251,0,1,1,17.251-17.251A17.251,17.251,0,0,1,665.4,651.869Z"
              transform="translate(-642.823 -612.028)"
              fill="#fff"
            />
            <path
              id="Path_2549_-_Outline"
              data-name="Path 2549 - Outline"
              d="M663.1,612.019h4.851l2.151,3.028c.438.107.876.229,1.306.366l3.265-1.494,4.2,2.42.349,3.706c.32.305.635.628.94.963l3.583.338,2.425,4.2-1.548,3.375c.131.445.244.887.337,1.317l2.93,2.077v4.855l-3.028,2.15c-.1.425-.224.855-.366,1.3L686,643.884l-2.42,4.2-3.708.353c-.311.325-.633.639-.96.937l-.34,3.583-4.2,2.428L671,653.842c-.411.121-.844.231-1.314.336l-2.078,2.927h-4.854l-2.149-3.022c-.43-.1-.869-.226-1.309-.365l-3.267,1.492-4.2-2.427-.352-3.706c-.316-.3-.63-.624-.936-.957l-3.587-.34-2.427-4.2,1.546-3.377c-.126-.424-.239-.865-.337-1.315l-2.925-2.083v-4.848l3.023-2.151c.111-.449.233-.887.364-1.305l-1.493-3.267,2.426-4.2,3.7-.347c.28-.3.6-.607.959-.941l.34-3.585,4.2-2.424,3.378,1.544c.445-.128.886-.24,1.314-.333Zm4.345.981h-3.838l-2.01,2.831-.189.039c-.516.107-1.055.243-1.6.405l-.176.052-3.251-1.486-3.32,1.918-.328,3.459-.141.128c-.459.417-.841.792-1.167,1.146l-.128.139-3.573.334-1.92,3.327,1.44,3.152-.059.181c-.163.5-.312,1.04-.444,1.593l-.043.179-2.91,2.07V636.3l2.826,2.012.039.188c.114.549.251,1.085.408,1.593l.055.179-1.49,3.256,1.92,3.32,3.462.328.128.141c.372.412.757.806,1.144,1.17l.135.127.339,3.57,3.32,1.92,3.153-1.44.182.059c.536.174,1.072.323,1.591.443l.181.042,2.07,2.912H667.1L669.1,653.3l.187-.04c.587-.126,1.11-.26,1.6-.41l.178-.055,3.251,1.49,3.322-1.921.328-3.461.143-.128c.4-.358.795-.743,1.171-1.142l.127-.135,3.571-.34,1.915-3.327-1.442-3.149.059-.182c.18-.554.326-1.073.445-1.588l.042-.181,2.917-2.072V632.82l-2.831-2.007-.039-.19c-.106-.517-.244-1.055-.409-1.6l-.054-.178,1.492-3.251-1.918-3.321-3.461-.326-.128-.144c-.368-.412-.753-.806-1.144-1.172l-.136-.127-.336-3.57-3.327-1.915-3.154,1.443-.182-.06c-.52-.171-1.055-.321-1.588-.445l-.181-.042Zm-2.062,3.867a17.687,17.687,0,1,1-6.906,1.394A17.631,17.631,0,0,1,665.388,616.866Zm0,34.5a16.761,16.761,0,1,0-16.76-16.761A16.78,16.78,0,0,0,665.388,651.369Z"
              transform="translate(-642.813 -612.019)"
              fill="#fff"
            />
            <path
              id="Path_2550"
              data-name="Path 2550"
              d="M659.922,619.705a9.575,9.575,0,1,0,9.574,9.574A9.575,9.575,0,0,0,659.922,619.705Zm0,16.435a6.862,6.862,0,1,1,6.862-6.861A6.861,6.861,0,0,1,659.922,636.14Z"
              transform="translate(-637.697 -606.792)"
              fill="#ff8e02"
            />
            <path
              id="Path_2550_-_Outline"
              data-name="Path 2550 - Outline"
              d="M659.912,619.205a10.065,10.065,0,1,1-10.066,10.064A10,10,0,0,1,659.912,619.205Zm0,19.149a9.084,9.084,0,1,0-9.085-9.085A9.095,9.095,0,0,0,659.912,638.354Zm0-16.439a7.353,7.353,0,1,1-7.352,7.354A7.361,7.361,0,0,1,659.912,621.915Zm0,13.725a6.372,6.372,0,1,0-6.371-6.371A6.378,6.378,0,0,0,659.912,635.64Z"
              transform="translate(-637.687 -606.782)"
              fill="#ff8e02"
            />
          </g>
          <path
            id="Path_3240"
            data-name="Path 3240"
            d="M36.642,4.17v-23.14L29.279-2.7H22.844L15.481-18.971V4.17H3.416V-39.76H16.595l9.467,20.666L35.59-39.76H48.707V4.17ZM70.115-39.76H81.128L96.349,4.17H84.1l-2.661-8.6H69.744l-2.6,8.6H54.832Zm9.4,27.286-3.9-13.612L71.538-12.474Zm53.149-16.768H120.047V4.17H107.981V-29.242H95.3V-39.76h37.371ZM139.536,4.17V-39.76h20.171a13.346,13.346,0,0,1,5.94,1.33,15.418,15.418,0,0,1,4.671,3.465,16.181,16.181,0,0,1,3.063,4.888,14.663,14.663,0,0,1,1.114,5.538,15.637,15.637,0,0,1-1.671,7.146,14.57,14.57,0,0,1-4.64,5.414L177.465,4.17H163.853L156.118-9.319H151.6V4.17ZM151.6-19.837h7.61a2.67,2.67,0,0,0,2.073-1.237,5.538,5.538,0,0,0,.959-3.465,4.976,4.976,0,0,0-1.114-3.5,3.121,3.121,0,0,0-2.227-1.207h-7.3ZM183.776,4.17V-39.76h12.065V4.17Zm30.689-43.93,7.982,13.86,7.982-13.86h12.993L228.757-17.486,242.926,4.17H229.933L222.446-9.071,214.96,4.17H201.9l14.169-21.656L201.409-39.76Z"
            transform="translate(168.818 39.933)"
            fill="#ff8e02"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
