import {
  Grid,
  useMediaQuery,
  Button,
  Dialog,
  Select,
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  MenuItem,
  TextField as MyTextField,
  Typography,
  Avatar,
  CircularProgress,
  makeStyles,
  Divider,
  Slider,
  Switch,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { stringify } from "query-string";
import * as React from "react";
import { DropzoneArea } from "material-ui-dropzone";
import {
  List,
  EmailField,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  required,
  PasswordInput,
  SelectInput,
  FormDataConsumer,
  useCreate,
  FunctionField,
  ReferenceInput,
  SelectField,
  ExportButton,
  CreateButton,
  TopToolbar,
  ChipField,
  NullableBooleanInput,
  FileInput,
  FileField,
  useMutation,
} from "react-admin";
import {
  ChangeStatus,
  ChangeUserStatus,
  FullName,
  FullNameField,
  StatusDisplay,
} from "../components/CustomFields";
import { BASE_URL } from "../config";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";

export const UserList = ({ permissions, ...props }) => {
  const classes = useStyles();

  return (
    <List
      // filters={[
      //   <ReferenceInput
      //     label="User"
      //     source="id"
      //     reference={permissions?.some((val) => ["SUPER-ADMIN"].includes(val))}
      //     validate={[required()]}
      //   >
      //     <SelectInput optionText="first_name" />
      //   </ReferenceInput>,
      // ]}
      filters={[<TextInput fullWidth label="Search" source="q" alwaysOn />]}
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid size="medium" padding="normal">
        <FunctionField
          label="Full Name"
          render={(record) => (
            <Box display="flex" alignItems="center">
              <Avatar
                src={record?.profile_picture}
                style={{
                  width: parseInt(25, 16),
                  height: parseInt(25, 16),
                  marginRight: 8,
                  marginTop: -4,
                  marginBottom: -4,
                }}
              />
              <Typography>
                {record.first_name} {record.middle_name}
              </Typography>
            </Box>
          )}
        />
        <EmailField source="email" />
        <TextField source="phone" />
        <FunctionField
          label="Role"
          render={(record) => {
            if (record?.system) {
              return (
                <Chip style={{ width: 120 }} label={record?.system?.role} />
              );
            } else if (record?.company) {
              return (
                <Chip style={{ width: 120 }} label={record?.company?.role} />
              );
            } else if (record?.garage) {
              return (
                <Chip style={{ width: 120 }} label={record?.garage?.role} />
              );
            }
          }}
        />
        <ChangeUserStatus resource="users" source="status" />
        {/* <FunctionField
        label="Status"
        render={(record) => {
          const [approve, { loading }] = useMutation({
            type: "update",
            resource: "users",
            payload: {
              id: `${record?.id}/${
                record?.status === "ACTIVE" ? "activate" : "block"
              }`,
            },
          });

          return (
            record && (
              <Alert
                severity={record?.status === "Active" ? "success" : "error"}
                style={{ width: 170 }}
                action={
                  <Switch
                    checked={record?.status === "Active" ? true : false}
                    onChange={approve}
                    disabled={loading}
                    color="primary"
                  />
                }
              >
                <Typography variant="body1">{record?.status}</Typography>
              </Alert>
            )
          );
        }}
      /> */}
        {permissions?.some((val) =>
          ["SUPER-ADMIN", "COMPANY-ADMIN", "GARAGE-ADMIN"].includes(val)
        ) && <EditButton />}
      </Datagrid>
    </List>
  );
};

// export const UserEdit = ({ permissions, ...props }) => {
//   const [list, setList] = React.useState();
//   const Roles = [
//     ...(permissions?.some((val) => ["SUPER-ADMIN"].includes(val))
//       ? [{ id: "COMPANY-ADMIN" }, { id: "SYSTEM-CLERK" }]
//       : []),
//     ...(permissions?.some((val) => ["COMPANY-ADMIN"].includes(val))
//       ? [{ id: "GARAGE-ADMIN" }, { id: "COMPANY-CLERK" }]
//       : []),
//     ...(permissions?.some((val) => ["GARAGE-ADMIN"].includes(val))
//       ? [{ id: "INSPECTOR" }, { id: "GARAGE-CLERK" }]
//       : []),
//   ];
//   const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
//   const [url, setUrl] = React.useState();

//   const handleClick = (files) => {
//     if (!files || !files[0]) {
//       return;
//     }

//     const formData = new FormData();

//     const { token } = JSON.parse(localStorage.getItem("auth"));

//     const config = {
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     };

//     // let file = files && files?.length > 0 && files[0]

//     //   // Update the formData object
//     //   formData.append(
//     //     "file",
//     //     file,
//     //     file?.name
//     //   );
//     formData.append("file", files[0]);
//     axios.post(`${BASE_URL}/assets`, formData, config).then((data) => {
//       // console.log({data: data?.data?.data})
//       setUrl(data?.data?.data?.file_path);
//     });
//     // create("assets", formData );
//   };

//   const transform = (data) => {
//     const formData = new FormData();
//     for (let key in data) {
//       if (key === "photo_file") {
//         console.log({ data_key: data[key] });
//         formData.append("photo_file", data[key]?.rawFile);
//       } else {
//         if (Array.isArray(data[key]) === true) {
//           data[key]?.forEach((each, i) => {
//             formData.append(key, JSON.stringify(each));
//           });
//         } else {
//           formData.append(key, data[key]);
//         }
//       }
//     }

//     console.log(formData);

//     return formData;
//     // {
//     //   ...data,
//     //   photo: url,
//     // };
//   };

//   return (
//     <Edit {...props} undoable={false} transform={transform}>
//       <SimpleForm>
//         {/* <DropzoneArea showAlerts={false} onChange={handleClick} /> */}
//         <ImageInput
//           source="photo_file"
//           label="Related pictures"
//           accept="image/*"
//         >
//           <ImageField source="src" title="title" />
//         </ImageInput>

//         <FormDataConsumer>
//           {({ formData, ...rest }) => {
//             // console.log({logoFile: formData.logo_file})
//             return formData.photo_file ? null : (
//               <ImageField source="photo" title="title" />
//             );
//           }}
//         </FormDataConsumer>

//         <Grid style={{ width: "100%" }} container spacing={3}>
//           <Grid item xs={12} sm={6} md={4}>
//             <TextInput source="username" />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <TextInput source="email" />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <TextInput source="phone" validate={required()} />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <TextInput source="first_name" />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <TextInput source="middle_name" />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <TextInput source="last_name" />
//           </Grid>
//           <Grid item xs={12} sm={6} md={4}>
//             <SelectInput
//               source="role"
//               choices={Roles}
//               optionText="id"
//               optionValue="id"
//             />
//           </Grid>
//           <Grid item xs={true} sm={true} spacing={0} md={4}>
//             <ReferenceInput
//               source="company_id"
//               reference={
//                 permissions === "COMPANY-ADMIN" ? "garages" : "companies"
//               }
//             >
//               <SelectInput optionText="name" />
//             </ReferenceInput>
//           </Grid>
//           {/* <Grid item xs={12} sm={6} md={4}>
//           </Grid> */}
//         </Grid>
//       </SimpleForm>
//     </Edit>
//   );
// };

export const UserEdit = ({ permissions, ...props }) => {
  const transform = (data) => {
    const { role, company, garage, ...rest } = data;

    const formData = new FormData();
    for (let key in rest) {
      if (key === "photo_file") {
        console.log({ data_key: rest[key] });
        formData.append("photo_file", rest[key]?.rawFile);
      } else if (key === "residential_id_file") {
        console.log({ data_key: rest[key] });
        formData.append("residential_id_file", rest[key]?.rawFile);
      } else {
        if (Array.isArray(rest[key]) === true) {
          rest[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, rest[key]);
        }
      }
    }

    const extra = {
      role,
      ...(company ? { company_id: company } : { garage_id: garage }),
    };

    formData.append("extra", JSON.stringify(extra));

    console.log(formData);

    return formData;
  };

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          container
          justifyContent="space-around"
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Profile Picture
                </Typography>
              }
              source="photo_file"
              maxSize={500000}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.photo_file ? null : (
                  <ImageField
                    source="profile_picture"
                    title="Prifile Picture"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Residential ID File
                </Typography>
              }
              maxSize={500000}
              source="residential_id_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.residential_id_file ? null : (
                  <FileField
                    source="residential_id"
                    title="Residential ID File"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          container
          spacing={2}
          justifyContent="space-around"
        >
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="first_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="middle_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="last_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="user_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="email" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <PasswordInput fullWidth source="password" />
          </Grid>
          <Grid item sm={12} md={5}>
            <PasswordInput fullWidth source="confirm_password" />
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              fullWidth
              source="gender"
              choices={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
              ]}
            />
          </Grid>
          <Grid item sm={12} md={5}></Grid>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData?.company ? (
                  <Box flex={1}>
                    <Typography
                      style={{ fontSize: "10px", margin: 0, padding: 0 }}
                      variant="body2"
                      color="textPrimary"
                    >
                      Role
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {formData?.company?.role}
                    </Typography>
                  </Box>
                ) : formData?.garage ? (
                  <Box flex={1}>
                    <Typography
                      style={{ fontSize: "10px", margin: 0, padding: 0 }}
                      variant="body2"
                      color="textPrimary"
                    >
                      Role
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {formData?.garage?.role}
                    </Typography>
                  </Box>
                ) : null
              }
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData?.company ? (
                  <ReferenceInput
                    source="company.company_id"
                    reference="companies"
                  >
                    <SelectInput disabled fullWidth optionText="name" />
                  </ReferenceInput>
                ) : formData?.garage ? (
                  <ReferenceInput source="garage.garage_id" reference="garages">
                    <SelectInput disabled fullWidth optionText="name" />
                  </ReferenceInput>
                ) : null
              }
            </FormDataConsumer>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

//   const [url, setUrl] = React.useState();

//   const handleClick = (files) => {
//     if (!files || !files[0]) {
//       return;
//     }

//     const formData = new FormData();

//     const { token } = JSON.parse(localStorage.getItem("auth"));

//     const config = {
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     };

//     // let file = files && files?.length > 0 && files[0]

//     //   // Update the formData object
//     //   formData.append(
//     //     "file",
//     //     file,
//     //     file?.name
//     //   );
//     formData.append("file", files[0]);
//     axios.post(`${BASE_URL}/assets`, formData, config).then((data) => {
//       // console.log({data: data?.data?.data})
//       setUrl(data?.data?.data?.file_path);
//     });
//     // create("assets", formData );
//   };

//   return (
//     <Create {...props} transform={transform}>
//       <SimpleForm>
//         <DropzoneArea showAlerts={false} onChange={handleClick} />
//       </SimpleForm>
//     </Create>
//   );
// };

export const UserCreate = ({ permissions, ...props }) => {
  const classes = useStyles();
  const Roles = [
    ...(permissions?.some((val) => ["SUPER-ADMIN"].includes(val))
      ? [
          { role: "COMPANY_USER", id: "COMPANY-ADMIN" },
          { role: "SYSTEM_USER", id: "SYSTEM-CLERK" },
        ]
      : []),
    ...(permissions?.some((val) => ["COMPANY-ADMIN"].includes(val))
      ? [
          { role: "GARAGE_USER", id: "GARAGE-ADMIN" },
          { role: "COMPANY_USER", id: "COMPANY-CLERK" },
          { role: "COMPANY_USER", id: "LEGAL-DOCUMENT-APPROVER" },
          { role: "COMPANY_USER", id: "BANK-ACCOUNT-APPROVER" },
        ]
      : []),
    ...(permissions?.some((val) => ["GARAGE-ADMIN"].includes(val))
      ? [
          { role: "GARAGE_USER", id: "INSPECTOR" },
          { role: "GARAGE_USER", id: "GARAGE-CLERK" },
        ]
      : []),
  ];

  const transform = (data) => {
    const { role, company, garage, ...rest } = data;

    const formData = new FormData();
    for (let key in rest) {
      if (key === "photo_file") {
        console.log({ data_key: rest[key] });
        formData.append("photo_file", rest[key]?.rawFile);
      } else if (key === "residential_id_file") {
        console.log({ data_key: rest[key] });
        formData.append("residential_id_file", rest[key]?.rawFile);
      } else {
        if (Array.isArray(rest[key]) === true) {
          rest[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, rest[key]);
        }
      }
    }

    const extra = {
      role,
      ...(company ? { company_id: company } : { garage_id: garage }),
    };

    formData.append("extra", JSON.stringify(extra));

    console.log(formData);

    return formData;
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          container
          justifyContent="space-around"
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Profile Picture
                </Typography>
              }
              source="photo_file"
              maxSize={500000}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Residential ID File
                </Typography>
              }
              maxSize={500000}
              source="residential_id_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          container
          spacing={2}
          justifyContent="space-around"
        >
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={required()} source="first_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={required()} source="middle_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={required()} source="last_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={required()} source="user_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={required()} source="email" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <PasswordInput fullWidth source="password" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <PasswordInput
              fullWidth
              source="confirm_password"
              validate={required()}
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              fullWidth
              source="gender"
              validate={required()}
              choices={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
              ]}
            />
          </Grid>
          <Grid item sm={12} md={5}></Grid>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              source="role"
              fullWidth
              validate={required()}
              choices={Roles}
              optionText="id"
              optionValue="id"
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.role === "COMPANY-ADMIN" ? (
                  <ReferenceInput
                    validate={required()}
                    source="company"
                    reference="companies"
                  >
                    <SelectInput fullWidth optionText="name" />
                  </ReferenceInput>
                ) : formData.role === "GARAGE-ADMIN" ? (
                  <ReferenceInput
                    validate={required()}
                    source="garage"
                    reference="garages"
                  >
                    <SelectInput fullWidth optionText="name" />
                  </ReferenceInput>
                ) : null
              }
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                if (!formData.role) {
                  return;
                }
                Roles.map((item, i) => {
                  if (item?.id === formData.role) {
                    formData.roles = item?.role;
                  }
                });

                return (
                  formData.role && (
                    <TextInput
                      disabled
                      hidden
                      fullWidth
                      label="System Role"
                      validate={required()}
                      source="roles"
                    />
                  )
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  SimpleFormIterator: {
    // display: "flex",
    // direction: "column",
    width: "100%",
    alignItem: "center",
    justifyContent: "center",
    // backgroundColor: "green",
  },
  first: {
    // backgroundColor: "red",
    float: "left",
    clear: "both",
    marginRight: "40px",
    // flex: 1,
  },
  email: {
    // width: 50,
    maxWidth: 150,
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
  second: {
    // backgroundColor: "yellow",
    // flex: 1,
  },
});
