import {
  Grid,
  Paper,
  makeStyles,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import * as React from "react";
import {
  List,
  SimpleForm,
  DeleteButton,
  TextInput,
  Create,
  useListContext,
} from "react-admin";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import { InspectionLogo } from "../assets/InspectionLogo";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles((theme) => ({
  root: {},
  body: {
    borderWidth: 0,
    borderTopWidth: "3px",
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
}));

const VehicleTypeGrid = (props) => {
  const classes = useStyles(props);
  const { data, ids } = useListContext();
  return ids ? (
    <Grid container spacing={3} className={classes.root}>
      {ids.map((id) => (
        <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
          <Paper elevation={2}>
            <Box py={3} display="flex" justifyContent="center">
              <Typography variant="h3">{data[id]?.id}</Typography>
            </Box>
            <Box mx={3} py={3} className={classes.body}>
              <Box display="flex" alignItems="center">
                <DriveEtaIcon fontSize="small" color="secondary" />
                <Box pl={1} />
                <Typography>{data[id]?.vehicles} Vehicles</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <SettingsIcon fontSize="small" color="secondary" />
                <Box pl={1} />
                <Typography>{data[id]?.configs} Configurations</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <InspectionLogo
                  // style={{ marginLeft: 5, backgroundColor: "yellow" }}
                  fontSize="small"
                  color="secondary"
                />
                <Box pl={1} />
                <Typography>{data[id]?.inspections} Inspections</Typography>
              </Box>
            </Box>
            <Divider />
            <Box
              mx={3}
              py={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {data[id]?.configurations?.[0]?.version ? (
                <Typography style={{ fontWeight: "bold" }} variant="overline">
                  V - {data[id]?.configurations?.[0]?.version}
                </Typography>
              ) : (
                <Typography style={{ fontSize: 12 }} variant="body2">
                  No active version
                </Typography>
              )}
              {!(data[id]?.configs || data[id]?.vehicles) &&
                props?.permissions?.some((val) =>
                  ["COMPANY-ADMIN"].includes(val)
                ) && (
                  <DeleteButton
                    undoable={false}
                    basePath="/vehicletypes"
                    record={data[id]}
                  />
                )}
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

export const VehicleTypeList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    {...props}
    sort={{ field: "created_at", order: "DESC" }}
    page={-1}
    pagination={false}
    component="div"
    // actions={false}
  >
    <VehicleTypeGrid permissions={permissions} />
  </List>
);

export const VehicleTypeCreate = ({ permissions, ...props }) => {
  const transform = (data) => {
    const formData = new FormData();
    for (let key in data) {
      if (key === "photo_file") {
        console.log({ data_key: data[key] });
        formData.append("photo_file", data[key]?.rawFile);
      } else {
        if (Array.isArray(data[key]) === true) {
          data[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    console.log(formData);

    return formData;
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="id" label="Vehicle Type Name" />
      </SimpleForm>
    </Create>
  );
};
