// in src/MyLayout.js
import * as React from "react";
import {
  AppBar,
  UserMenu,
  Sidebar,
  MenuItemLink,
  Layout,
  DashboardMenuItem,
  Menu,
  usePermissions,
} from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import GradeIcon from "@material-ui/icons/Grade";
import { Box, makeStyles } from "@material-ui/core";
import AutomatrixLogo from "./assets/Logo";
import PeopleIcon from "@material-ui/icons/People";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ReceiptIcon from "@material-ui/icons/Receipt";
import BusinessIcon from "@material-ui/icons/Business";
import { GarageLogo } from "./assets/GarageLogo";
import { InspectionLogo } from "./assets/InspectionLogo";
import { VehicleTypeLogo } from "./assets/VehicleTypeLogo";
import { DriverLogo } from "./assets/DriverLogo";
import { OwnerLogo } from "./assets/OwnerLogo";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
// import MyAppBar from './MyAppBar';
// import MyMenu from './MyMenu';
// import MyNotification from './MyNotification';

const CustomLayout = (props) => (
  <Layout
    {...props}
    appBar={MyAppBar}
    sidebar={MySidebar}
    menu={MyMenu}
    // notification={MyNotification}
  />
);

export default CustomLayout;

const ConfigurationMenu = React.forwardRef(({ onClick }, ref) => (
  <MenuItemLink
    ref={ref}
    to="/configuration"
    primaryText="Configuration"
    leftIcon={<SettingsIcon />}
    onClick={onClick} // close the menu on click
  />
));

const MyUserMenu = (props) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
);

const useSidebarStyles = makeStyles((theme) => ({
  drawerPaper: {
    // backgroundColor: "#1D1A2F",
    height: "100vh",
  },
  root: {
    color: "white",
    paddingRight: 16,
  },
  active: {
    color: "white",
  },
  icon: {
    color: "inherit",
  },
  logo: {
    fontSize: theme.breakpoints.down("sm") ? "80px" : "30px",
    // margin: "auto",
    marginLeft: "70px",
    marginRight: "70px",
    marginBottom: "10px",
  },
}));

const MySidebar = (props) => {
  const classes = useSidebarStyles();
  return <Sidebar classes={classes} {...props} />;
};

const useStyles = makeStyles({
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
});

const MyAppBar = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export const MyMenu = (props) => {
  const { permissions } = usePermissions();
  console.log({ permissions });
  const classes = useSidebarStyles();
  return (
    <Menu {...props}>
      <Box
        height="100vh"
        width="inherit"
        mt="-55px"
        pt="55px"
        bgcolor="#1D1A2F"
        overflow="hidden"
        position="fixed"
      >
        <AutomatrixLogo className={classes.logo} />

        {permissions?.some((val) =>
          ["SUPER-ADMIN", "COMPANY-ADMIN", "GARAGE-ADMIN"].includes(val)
        ) && <DashboardMenuItem />}

        {permissions?.some((val) =>
          ["SUPER-ADMIN", "SYSTEM-CLERK"].includes(val)
        ) && (
          <MenuItemLink
            to="/companies"
            primaryText="Companies"
            leftIcon={<BusinessIcon />}
          />
        )}

        <MenuItemLink
          to="/users"
          primaryText="Users"
          leftIcon={<PeopleIcon />}
        />

        {permissions?.some((val) =>
          [
            "SUPER-ADMIN",
            "SYSTEM-CLERK",
            "COMPANY-ADMIN",
            "COMPANY-CLERK",
          ].includes(val)
        ) && (
          <MenuItemLink
            to="/garages"
            primaryText="Garages"
            leftIcon={<GarageLogo />}
          />
        )}

        <MenuItemLink
          to="/inspections"
          primaryText="Inspections"
          leftIcon={<InspectionLogo />}
        />
        <MenuItemLink
          to="/vehicletypes"
          primaryText="Vehicle Types"
          leftIcon={<VehicleTypeLogo fontSize="small" />}
        />

        {permissions?.some((val) =>
          ["SUPER-ADMIN", "GARAGE-ADMIN", "COMPANY-ADMIN"].includes(val)
        ) && (
          <MenuItemLink
            to="/invoices"
            primaryText="Invoices"
            leftIcon={<ReceiptIcon />}
          />
        )}

        <MenuItemLink
          to="/configurations"
          primaryText="Configurations"
          leftIcon={<SettingsIcon />}
        />

        {permissions?.some((val) =>
          [
            "SUPER-ADMIN",
            "SYSTEM-CLERK",
            "COMPANY-ADMIN",
            "COMPANY-CLERK",
            "LEGAL-DOCUMENT-APPROVER",
            "BANK-ACCOUNT-APPROVER",
          ].includes(val)
        ) && (
          <MenuItemLink
            to="/vehicles"
            primaryText="Vehicles"
            leftIcon={<DriveEtaIcon />}
          />
        )}

        {permissions?.some((val) =>
          [
            "SUPER-ADMIN",
            "SYSTEM-CLERK",
            "COMPANY-ADMIN",
            "COMPANY-CLERK",
            "LEGAL-DOCUMENT-APPROVER",
            "BANK-ACCOUNT-APPROVER",
          ].includes(val)
        ) && (
          <MenuItemLink
            to="/owners"
            primaryText="Owners"
            leftIcon={<OwnerLogo fontSize="small" />}
          />
        )}

        {permissions?.some((val) =>
          [
            "SUPER-ADMIN",
            "SYSTEM-CLERK",
            "COMPANY-ADMIN",
            "COMPANY-CLERK",
            "LEGAL-DOCUMENT-APPROVER",
            "BANK-ACCOUNT-APPROVER",
          ].includes(val)
        ) && (
          <MenuItemLink
            to="/drivers"
            primaryText="Drivers"
            leftIcon={<DriverLogo fontSize="small" />}
          />
        )}

        <MenuItemLink
          to="/grades"
          primaryText="Grades"
          leftIcon={<GradeIcon />}
        />

        {permissions?.some((val) =>
          ["SUPER-ADMIN", "COMPANY-ADMIN"].includes(val)
        ) && (
          <MenuItemLink
            to="/activities"
            primaryText="Activity Log"
            leftIcon={<TrackChangesIcon />}
          />
        )}
      </Box>
    </Menu>
  );
};
