import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Create,
  required,
  ImageField,
  FunctionField,
  EmailField,
  FileInput,
  FileField,
  Edit,
  EditButton,
  SelectInput,
  FormDataConsumer,
  SelectField,
  useMutation,
  ShowButton,
  ReferenceInput,
  useListContext,
  CreateButton,
  Show,
  useNotify,
  useRedirect,
  useRefresh,
  useQuery,
} from "react-admin";
import {
  Avatar,
  Box,
  Grid,
  Divider,
  Typography,
  Button,
  InputAdornment,
  MenuItem,
  CircularProgress,
  TextField as MuiTextField,
} from "@material-ui/core";
import { ChangeDocumentStatus, ChangeStatus } from "../components/CustomFields";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import SearchIcon from "@material-ui/icons/Search";
import { Form } from "react-final-form";
import { BASE_URL } from "../config";
import axios from "axios";

const PostFilterForm = () => {
  const { filterValues, setFilters } = useListContext();

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      for (const [key, value] of Object.entries(values)) {
        if (key === "search") {
          if (isNaN(value)) {
            setFilters({ email: value });
          } else {
            setFilters({ phone: value });
          }
        }
      }
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2}>
                <TextInput
                  resettable
                  helperText={false}
                  variant="outlined"
                  source="search"
                  label="Search by email or phone number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={1} mb={1.5}>
                <Button color="primary" type="submit">
                  Search
                </Button>
              </Box>
              <Box component="span" mb={1.5}>
                <Button onClick={resetFilter}>Reset</Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

const ListActions = ({ permissions }) => (
  <Box width="100%" display="flex" justifyContent="space-between" m={1} mt={2}>
    <PostFilterForm />
    {permissions?.some((val) => ["COMPANY-CLERK"].includes(val)) && (
      <CreateButton />
    )}
  </Box>
);

export const DriverList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    sort={{ field: "created_at", order: "DESC" }}
    {...(permissions?.some((val) =>
      ["SUPER-ADMIN", "COMPANY-ADMIN"].includes(val)
    )
      ? {
          filters: [<TextInput fullWidth label="Search" source="q" alwaysOn />],
        }
      : { actions: <ListActions permissions={permissions} /> })}
    empty={false}
    {...props}
  >
    <Datagrid size="medium" padding="normal">
      <FunctionField
        label="Full Name"
        render={(record) => (
          <Box display="flex">
            <Avatar
              src={record?.profile_picture}
              style={{
                width: parseInt(25, 16),
                height: parseInt(25, 16),
                marginRight: 8,
                marginTop: -4,
                marginBottom: -4,
              }}
            />
            <Typography>
              {record?.first_name} {record?.middle_name}
            </Typography>
          </Box>
        )}
      />

      <EmailField source="email" />
      <TextField source="phone" />
      <ChangeStatus resource="drivers" source="status" />
      <ShowButton />
      {permissions?.some((val) =>
        ["SUPER-ADMIN", "COMPANY-ADMIN", "GARAGE-ADMIN"].includes(val)
      ) && <EditButton />}
    </Datagrid>
  </List>
);

export const DriverShow = (props) => {
  const { access_token } = JSON.parse(localStorage.getItem("auth"));
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [status, setStatus] = React.useState();
  const [bank, setBank] = React.useState();
  const [account, setAccount] = React.useState();

  // const [banks, setBanks] = React.useState();

  // React.useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: `${BASE_URL}/banks`,
  //     headers: {
  //       Authorization: `Bearer ${access_token}`,
  //     },
  //   }).then((data) => {
  //     setBanks(data?.data?.data);
  //   });
  // }, []);

  const {
    data: banks,
    loading: banksLoading,
    error: banksError,
  } = useQuery({
    type: "getList",
    resource: "banks",
    payload: {
      pagination: { page: -1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    },
  });

  const [approve, { loading }] = useMutation({
    type: "update",
    resource: props?.resource,
    payload: {
      id: `${props?.id}/${status ? "approve-document" : "reject-document"}`,
    },
  });

  const submit = () => {
    const formData = new FormData();
    formData.append("bank_account", account);
    formData.append("bank_name", bank);
    // return;
    axios({
      method: "patch",
      url: `${BASE_URL}/drivers/${props?.id}/approve-bank-account`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: formData,
    })
      .then((data) => {
        // console.log({ data: data?.data });
        notify("Successfuly updated bank account!");
        redirect("/drivers");
        refresh();
      })
      .catch((err) => {
        console.log({ err });
        notify(err?.response?.data?.errors?.title || "Network error", {
          type: "warning",
        });
      });
  };

  React.useEffect(() => {
    if (status === true) {
      approve();
    } else if (status === false) {
      approve();
    }
  }, [status]);

  if (
    props?.permissions?.some((val) => ["BANK-ACCOUNT-APPROVER"].includes(val))
  ) {
    return (
      <Show {...props}>
        <SimpleForm toolbar={false}>
          {!banksLoading && banks && banks?.length > 0 && (
            <Grid
              style={{ width: "100%" }}
              justifyContent="space-around"
              container
              spacing={2}
            >
              <Grid item sm={12} md={5}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <MuiTextField
                      select
                      label="Select"
                      value={bank}
                      defaultValue={formData?.driver?.bank_name}
                      fullWidth
                      onChange={(event) => setBank(event.target.value)}
                      helperText="Please select your bank"
                    >
                      {banks?.map((option) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </MuiTextField>
                  )}
                </FormDataConsumer>
              </Grid>
              <Grid item sm={12} md={5}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <MuiTextField
                      label="Account"
                      fullWidth
                      defaultValue={formData?.driver?.bank_account}
                      value={account}
                      onChange={(event) => setAccount(event.target.value)}
                      helperText="Please enter your bank account"
                    />
                  )}
                </FormDataConsumer>
              </Grid>
              <Grid item sm={12} md={12}>
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Box component="span" mr={1} mb={1.5}>
                    <Button color="primary" onClick={() => submit()}>
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          {banksLoading && (
            <Box display="flex" justifyContent="center" p={1}>
              <CircularProgress color="primary" />
            </Box>
          )}
        </SimpleForm>
      </Show>
    );
  }

  return (
    <Show {...props}>
      <SimpleForm toolbar={false}>
        <Grid
          style={{ width: "100%" }}
          justifyContent="space-around"
          container
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontWeight: "bold",
              }}
              variant="body2"
            >
              Profile Picture
            </Typography>
            <ImageField
              fullWidth
              source="profile_picture"
              title="Prifile Picture"
            />
          </Grid>
          <Grid container item sm={12} md={5}>
            <Grid item sm={12} md={12}>
              <Box py={1}>
                <FileField
                  fullWidth
                  source="residential_id"
                  title="Residential ID File"
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={12}>
              <Box py={1}>
                <FileField
                  fullWidth
                  source="driver.driving_license"
                  title="Driving License File"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          spacing={2}
          justifyContent="space-around"
          container
        >
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              First Name
            </Typography>
            <TextField label="First Name" fullWidth source="first_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Middle Name
            </Typography>
            <TextField fullWidth source="middle_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Last Name
            </Typography>
            <TextField fullWidth source="last_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              First Name
            </Typography>
            <TextField fullWidth source="user_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Email
            </Typography>
            <TextField fullWidth source="email" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Phone
            </Typography>
            <TextField fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Gender
            </Typography>
            <TextField fullWidth source="gender" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Document status
            </Typography>
            <SelectField
              source="driver.legal_document_approval"
              choices={[
                { id: "PENDING", name: "Pending" },
                { id: "REJECTED", name: "Rejected" },
                { id: "APPROVED", name: "Approved" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData?.driver?.legal_document_approval === "PENDING" &&
            props?.permissions?.some((val) =>
              ["LEGAL-DOCUMENT-APPROVER"].includes(val)
            ) && (
              <Box p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignSelf="center"
                >
                  <Box color="success.main">
                    <Button
                      color="inherit"
                      startIcon={<ThumbUpIcon color="inherit" />}
                      disabled={loading}
                      onClick={() => {
                        setStatus(true);
                      }}
                    >
                      Approve
                    </Button>
                  </Box>
                  <Box px={4} />
                  <Box color="error.main">
                    <Button
                      color="inherit"
                      startIcon={<ThumbDownIcon color="inherit" />}
                      disabled={loading}
                      onClick={() => {
                        setStatus(false);
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Show>
  );
};

export const DriverEdit = (props) => {
  const transform = (rest) => {
    const formData = new FormData();
    for (let key in rest) {
      if (key === "photo_file") {
        console.log({ data_key: rest[key] });
        formData.append("photo_file", rest[key]?.rawFile);
      } else if (key === "driving_license_file") {
        console.log({ data_key: rest[key] });
        formData.append("driving_license_file", rest[key]?.rawFile);
      } else if (key === "residential_id_file") {
        console.log({ data_key: rest[key] });
        formData.append("residential_id_file", rest[key]?.rawFile);
      } else if (
        typeof rest[key] === "object" &&
        !Array.isArray(rest[key]) &&
        rest[key] !== null
      ) {
        formData.append(key, JSON.stringify(rest[key]));
      } else {
        if (Array.isArray(rest[key]) === true) {
          rest[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, rest[key]);
        }
      }
    }
    console.log(formData);

    return formData;
  };

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          container
          justifyContent="space-around"
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Profile Picture
                </Typography>
              }
              source="photo_file"
              maxSize={500000}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.photo_file ? null : (
                  <ImageField
                    source="profile_picture"
                    title="Prifile Picture"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Residential ID File
                </Typography>
              }
              maxSize={500000}
              source="residential_id_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.residential_id_file ? null : (
                  <FileField
                    source="residential_id"
                    title="Residential ID File"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Driving License File
                </Typography>
              }
              maxSize={500000}
              source="driving_license_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.driving_license_file ? null : (
                  <FileField
                    source="driver.driving_license"
                    title="Driving License File"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          container
          spacing={2}
          justifyContent="space-around"
        >
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="first_name" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="middle_name" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="last_name" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="user_name" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="email" />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, ...rest }) => (
                <TextInput
                  fullWidth
                  label="License id"
                  source="license_id"
                  initialValue={formData?.driver?.license_id}
                />
              )}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <SelectInput
              fullWidth
              source="gender"
              choices={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
              ]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const DriverCreate = (props) => {
  const transform = (rest) => {
    const formData = new FormData();
    for (let key in rest) {
      if (key === "photo_file") {
        console.log({ data_key: rest[key] });
        formData.append("photo_file", rest[key]?.rawFile);
      } else if (key === "driving_license_file") {
        console.log({ data_key: rest[key] });
        formData.append("driving_license_file", rest[key]?.rawFile);
      } else if (key === "residential_id_file") {
        console.log({ data_key: rest[key] });
        formData.append("residential_id_file", rest[key]?.rawFile);
      } else {
        if (Array.isArray(rest[key]) === true) {
          rest[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, rest[key]);
        }
      }
    }
    console.log(formData);

    return formData;
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          container
          justifyContent="space-around"
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Profile Picture
                </Typography>
              }
              source="photo_file"
              maxSize={500000}
              validate={[required()]}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Residential ID File
                </Typography>
              }
              maxSize={500000}
              validate={[required()]}
              source="residential_id_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Driving License File
                </Typography>
              }
              maxSize={500000}
              validate={[required()]}
              source="driving_license_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          container
          spacing={2}
          justifyContent="space-around"
        >
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={[required()]} source="first_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={[required()]} source="middle_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={[required()]} source="last_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="user_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="email" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput
              fullWidth
              validate={[required()]}
              label="License id"
              source="license_id"
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              fullWidth
              validate={[required()]}
              source="gender"
              choices={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
              ]}
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
