import { Grid, Divider, Box, Typography } from "@material-ui/core";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  required,
  SelectInput,
  FormDataConsumer,
  FileInput,
  ReferenceInput,
  FunctionField,
} from "react-admin";
import {
  AvatarField,
  ChangeEntityStatus,
  ChangeStatus,
} from "../components/CustomFields";

export const GarageList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    filters={[
      ...(permissions?.some((val) =>
        ["SUPER-ADMIN", "SYSTEM-CLERK"].includes(val)
      )
        ? [
            <ReferenceInput
              label="Company"
              source="company_id"
              reference="companies"
              validate={[required()]}
            >
              <SelectInput fullWidth optionText="name" />
            </ReferenceInput>,
          ]
        : []),
      <TextInput fullWidth label="Search" source="q" alwaysOn />,
    ]}
    sort={{ field: "created_at", order: "DESC" }}
    {...props}
  >
    <Datagrid size="medium" padding="normal">
      <AvatarField source="logo" />
      <TextField source="name" />
      <TextField source="phone" />
      <TextField source="address" />
      <ChangeEntityStatus resource="garages" source="status" />
      {permissions?.some((val) => ["COMPANY-ADMIN"].includes(val)) && (
        <EditButton />
      )}
    </Datagrid>
  </List>
);

// export const GarageEdit = ({ permissions, ...props }) => {
//   const transform = (data) => {
//     const formData = new FormData();
//     for (let key in data) {
//       if (key === "logo_file") {
//         console.log({ data_key: data[key] });
//         formData.append("logo_file", data[key]?.rawFile);
//       } else {
//         if (Array.isArray(data[key]) === true) {
//           data[key]?.forEach((each, i) => {
//             formData.append(key, JSON.stringify(each));
//           });
//         } else {
//           formData.append(key, data[key]);
//         }
//       }
//     }

//     console.log(formData);

//     return formData;
//   };

//   return (
//     <Edit {...props} transform={transform}>
//       <SimpleForm>
//         <ImageInput
//           source="logo_file"
//           label="Related pictures"
//           accept="image/*"
//         >
//           <ImageField source="src" title="Logo" />
//         </ImageInput>
//         <FormDataConsumer>
//           {({ formData, ...rest }) => {
//             // console.log({logoFile: formData.logo_file})
//             return formData.logo_file ? null : (
//               <ImageField source="logo" title="Logo" />
//             );
//           }}
//         </FormDataConsumer>
//         <TextInput source="name" />
//         <TextInput source="phone" validate={required()} />
//         <TextInput source="address" />
//       </SimpleForm>
//     </Edit>
//   );
// };

export const GarageEdit = ({ permissions, ...props }) => {
  const transform = (data) => {
    const formData = new FormData();
    for (let key in data) {
      if (key === "logo_file") {
        console.log({ data_key: data[key] });
        formData.append("logo_file", data[key]?.rawFile);
      } else {
        if (Array.isArray(data[key]) === true) {
          data[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    console.log(formData);

    return formData;
  };

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          justifyContent="space-around"
          container
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>Logo</Typography>
              }
              source="logo_file"
              maxSize={500000}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                // console.log({logoFile: formData.logo_file})
                return formData.logo_file ? null : (
                  <ImageField source="logo" title="Logo" />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        <Grid
          style={{ width: "100%" }}
          spacing={2}
          justifyContent="space-around"
          container
        >
          <Grid item sm={12} md={5}>
            <TextInput source="name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput source="phone" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput source="address" />
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const GarageCreate = ({ permissions, ...props }) => {
  const transform = (data) => {
    const formData = new FormData();
    for (let key in data) {
      if (key === "logo_file") {
        console.log({ data_key: data[key] });
        formData.append("logo_file", data[key]?.rawFile);
      } else {
        if (Array.isArray(data[key]) === true) {
          data[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    console.log(formData);

    return formData;
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          justifyContent="space-around"
          container
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Garage logo
                </Typography>
              }
              source="logo_file"
              maxSize={500000}
              validate={required()}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.logo_file ? null : (
                  <ImageField source="logo" title="Garage logo" />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        <Grid
          style={{ width: "100%" }}
          spacing={2}
          justifyContent="space-around"
          container
        >
          <Grid item sm={12} md={5}>
            <TextInput validate={required()} fullWidth source="name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput validate={required()} fullWidth source="address" />
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
