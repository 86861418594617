import SvgIcon from "@material-ui/core/SvgIcon";

export function InspectionLogo(props) {
  return (
    <SvgIcon
      {...props}
      id="InspectionLogo"
      xmlns="http://www.w3.org/2000/svg"
      width="23.133"
      height="23.133"
      viewBox="0 0 23.133 23.133"
      fill={props?.color || "fff"}
    >
      <path
        id="Path_502"
        data-name="Path 502"
        d="M0,0H23.133V23.133H0Z"
        fill="none"
      />
      <path
        id="Icon_awesome-tools"
        data-name="Icon awesome-tools"
        d="M17.435,13.769,13.36,9.694a2.584,2.584,0,0,0-2.972-.484L6.679,5.5V3.34L2.225,0,0,2.227,3.339,6.681H5.5L9.209,10.39a2.589,2.589,0,0,0,.484,2.972l4.075,4.075a1.294,1.294,0,0,0,1.834,0L17.435,15.6a1.3,1.3,0,0,0,0-1.834ZM11.54,7.829a3.661,3.661,0,0,1,2.606,1.079l.675.675a4.925,4.925,0,0,0,1.524-1.027A5,5,0,0,0,17.665,3.8a.417.417,0,0,0-.7-.191L14.376,6.2,12.014,5.8,11.62,3.441,14.209.853a.42.42,0,0,0-.2-.7A5.013,5.013,0,0,0,9.258,1.468a4.931,4.931,0,0,0-1.434,3.6L10.681,7.93a3.791,3.791,0,0,1,.859-.1ZM7.925,10.683,5.952,8.71l-5.3,5.307A2.227,2.227,0,0,0,3.8,17.165l4.3-4.3a3.737,3.737,0,0,1-.174-2.182Zm-5.7,5.741a.835.835,0,1,1,.835-.835A.838.838,0,0,1,2.225,16.424Z"
        transform="translate(2.753 2.657)"
        fill={props?.color || "fff"}
      />
    </SvgIcon>
  );
}
