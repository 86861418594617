import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Create,
  ImageField,
  required,
  FunctionField,
  EmailField,
  SelectInput,
  FileInput,
  FileField,
  EditButton,
  FormDataConsumer,
  Edit,
  Show,
  SelectField,
  useMutation,
  ShowButton,
  ReferenceInput,
  NullableBooleanInput,
  useListContext,
  ExportButton,
  CreateButton,
} from "react-admin";
import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  Grid,
  Button,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { ChangeStatus } from "../components/CustomFields";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import SearchIcon from "@material-ui/icons/Search";
import { Form } from "react-final-form";

const PostFilterForm = () => {
  const { filterValues, setFilters } = useListContext();

  // if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      for (const [key, value] of Object.entries(values)) {
        if (key === "search") {
          if (isNaN(value)) {
            setFilters({ email: value });
          } else {
            setFilters({ phone: value });
          }
        }
      }
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2}>
                <TextInput
                  resettable
                  helperText={false}
                  variant="outlined"
                  source="search"
                  label="Search by email or phone number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box component="span" mr={1} mb={1.5}>
                <Button color="primary" type="submit">
                  Search
                </Button>
              </Box>
              <Box component="span" mb={1.5}>
                <Button onClick={resetFilter}>Reset</Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

const ListActions = ({ permissions }) => (
  <Box width="100%" display="flex" justifyContent="space-between" m={1} mt={2}>
    <PostFilterForm />
    {permissions?.some((val) => ["COMPANY-CLERK"].includes(val)) && (
      <CreateButton />
    )}
  </Box>
);

export const OwnerList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    {...(permissions?.some((val) =>
      ["SUPER-ADMIN", "COMPANY-ADMIN"].includes(val)
    )
      ? {
          filters: [<TextInput fullWidth label="Search" source="q" alwaysOn />],
        }
      : { actions: <ListActions permissions={permissions} /> })}
    // filters={[
    //   <ReferenceInput
    //     label="Email"
    //     source="email"
    //     reference="owners"
    //     filterToQuery={(searchText) => {
    //       if (!searchText) {
    //         return;
    //       }
    //       // setTimeout(() => {

    //       // }, 2000)
    //       return isNaN(searchText)
    //         ? { email: searchText }
    //         : { phone: searchText };
    //     }}
    //     alwaysOn
    //     validate={[required()]}
    //   >
    //   <TextInput alwaysOn fullWidth source="email" />,
    //   </ReferenceInput>,
    //   <TextInput fullWidth label="Search" source="q" alwaysOn />,
    // ]}
    empty={false}
    sort={{ field: "created_at", order: "DESC" }}
    {...props}
  >
    <Datagrid size="medium" padding="normal">
      <FunctionField
        label="Full Name"
        render={(record) => (
          <Box display="flex">
            <Avatar
              src={record?.profile_picture}
              style={{
                width: parseInt(25, 16),
                height: parseInt(25, 16),
                marginRight: 8,
                marginTop: -4,
                marginBottom: -4,
              }}
            />
            <Typography>
              {record?.first_name} {record?.middle_name}
            </Typography>
          </Box>
        )}
      />

      <EmailField source="email" />
      <TextField source="phone" />
      <ChangeStatus resource="users" source="status" />
      <ShowButton />
      {permissions?.some((val) =>
        ["SUPER-ADMIN", "COMPANY-ADMIN", "GARAGE-ADMIN"].includes(val)
      ) && <EditButton />}
    </Datagrid>
  </List>
);

export const OwnerShow = (props) => {
  const [status, setStatus] = React.useState();

  const [approve, { loading }] = useMutation({
    type: "update",
    resource: props?.resource,
    payload: {
      id: `${props?.id}/${status ? "approve-document" : "reject-document"}`,
    },
  });

  React.useEffect(() => {
    if (status === true) {
      approve();
    } else if (status === false) {
      approve();
    }
  }, [status]);

  return (
    <Show {...props}>
      <SimpleForm toolbar={false}>
        <Grid
          style={{ width: "100%" }}
          justifyContent="space-around"
          container
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontWeight: "bold",
              }}
              variant="body2"
            >
              Profile Picture
            </Typography>
            <ImageField
              fullWidth
              source="profile_picture"
              title="Prifile Picture"
            />
          </Grid>
          <Grid container item sm={12} md={5}>
            <Grid item sm={12} md={12}>
              <Box py={1}>
                <FileField
                  fullWidth
                  source="residential_id"
                  title="Residential ID File"
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={12}>
              <Box py={1}>
                <FileField
                  fullWidth
                  source="owner.business_license"
                  title="Business License File"
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={12}>
              <Box py={1}>
                <FileField
                  fullWidth
                  source="owner.tin_certificate"
                  title="Tin Certificate File"
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={12}>
              <Box py={1}>
                <FileField
                  fullWidth
                  source="owner.commercial_registration_certificate"
                  title="Commercial Registration Certificate File"
                />
              </Box>
            </Grid>
            <Grid item sm={12} md={12}></Grid>
          </Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          spacing={2}
          justifyContent="space-around"
          container
        >
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              First Name
            </Typography>
            <TextField label="First Name" fullWidth source="first_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Middle Name
            </Typography>
            <TextField fullWidth source="middle_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Last Name
            </Typography>
            <TextField fullWidth source="last_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              First Name
            </Typography>
            <TextField fullWidth source="user_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Email
            </Typography>
            <TextField fullWidth source="email" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Phone
            </Typography>
            <TextField fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Gender
            </Typography>
            <TextField fullWidth source="gender" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Document status
            </Typography>
            <SelectField
              source="owner.legal_document_approval"
              choices={[
                { id: "PENDING", name: "Pending" },
                { id: "REJECTED", name: "Rejected" },
                { id: "APPROVED", name: "Approved" },
              ]}
            />
          </Grid>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData?.owner?.legal_document_approval === "PENDING" &&
            props?.permissions?.some((val) =>
              ["LEGAL-DOCUMENT-APPROVER"].includes(val)
            ) && (
              <Box p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignSelf="center"
                >
                  <Box color="success.main">
                    <Button
                      color="inherit"
                      startIcon={<ThumbUpIcon color="inherit" />}
                      disabled={loading}
                      onClick={() => {
                        setStatus(true);
                      }}
                    >
                      Approve
                    </Button>
                  </Box>
                  <Box px={4} />
                  <Box color="error.main">
                    <Button
                      color="inherit"
                      startIcon={<ThumbDownIcon color="inherit" />}
                      disabled={loading}
                      onClick={() => {
                        setStatus(false);
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Show>
  );
};

export const OwnerEdit = (props) => {
  const transform = (rest) => {
    const formData = new FormData();
    for (let key in rest) {
      if (key === "photo_file") {
        console.log({ data_key: rest[key] });
        formData.append("photo_file", rest[key]?.rawFile);
      } else if (key === "residential_id_file") {
        console.log({ data_key: rest[key] });
        formData.append("residential_id_file", rest[key]?.rawFile);
      } else if (key === "business_license_file") {
        console.log({ data_key: rest[key] });
        formData.append("business_license_file", rest[key]?.rawFile);
      } else if (key === "tin_certificate_file") {
        console.log({ data_key: rest[key] });
        formData.append("tin_certificate_file", rest[key]?.rawFile);
      } else if (key === "commercial_registration_certificate_file") {
        console.log({ data_key: rest[key] });
        formData.append(
          "commercial_registration_certificate_file",
          rest[key]?.rawFile
        );
      } else if (
        typeof rest[key] === "object" &&
        !Array.isArray(rest[key]) &&
        rest[key] !== null
      ) {
        formData.append(key, JSON.stringify(rest[key]));
      } else {
        if (Array.isArray(rest[key]) === true) {
          rest[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, rest[key]);
        }
      }
    }
    console.log(formData);

    return formData;
  };

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          justifyContent="space-around"
          container
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Profile Picture
                </Typography>
              }
              source="photo_file"
              maxSize={500000}
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.photo_file ? null : (
                  <ImageField
                    source="profile_picture"
                    title="Prifile Picture"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              maxSize={500000}
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Residential ID File
                </Typography>
              }
              source="residential_id_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.residential_id_file ? null : (
                  <FileField
                    source="residential_id"
                    title="Residential ID File"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              maxSize={500000}
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Business License File
                </Typography>
              }
              source="business_license_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.business_license_file ? null : (
                  <FileField
                    source="owner.business_license"
                    title="Business License File"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              maxSize={500000}
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Tin Certificate File
                </Typography>
              }
              source="tin_certificate_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.tin_certificate_file ? null : (
                  <FileField
                    source="owner.tin_certificate"
                    title="Tin Certificate File"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              maxSize={500000}
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Commercial Registration Certificate File
                </Typography>
              }
              source="commercial_registration_certificate_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.commercial_registration_certificate_file ? null : (
                  <FileField
                    source="owner.commercial_registration_certificate"
                    title="Commercial Registration Certificate File"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          spacing={2}
          justifyContent="space-around"
          container
        >
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="first_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="middle_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="last_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="user_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="email" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              fullWidth
              source="gender"
              choices={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
              ]}
            />
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const OwnerCreate = (props) => {
  const transform = (rest) => {
    const formData = new FormData();
    for (let key in rest) {
      if (key === "photo_file") {
        console.log({ data_key: rest[key] });
        formData.append("photo_file", rest[key]?.rawFile);
      } else if (key === "residential_id_file") {
        console.log({ data_key: rest[key] });
        formData.append("residential_id_file", rest[key]?.rawFile);
      } else if (key === "business_license_file") {
        console.log({ data_key: rest[key] });
        formData.append("business_license_file", rest[key]?.rawFile);
      } else if (key === "tin_certificate_file") {
        console.log({ data_key: rest[key] });
        formData.append("tin_certificate_file", rest[key]?.rawFile);
      } else if (key === "commercial_registration_certificate_file") {
        console.log({ data_key: rest[key] });
        formData.append(
          "commercial_registration_certificate_file",
          rest[key]?.rawFile
        );
      } else if (key === "driving_license_file") {
        console.log({ data_key: rest[key] });
        formData.append("driving_license_file", rest[key]?.rawFile);
      } else {
        if (Array.isArray(rest[key]) === true) {
          rest[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, rest[key]);
        }
      }
    }
    console.log(formData);

    return formData;
  };

  const [iAmDriver, setIAmDriver] = React.useState(false);

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <Grid
          style={{ width: "100%" }}
          justifyContent="space-around"
          container
          spacing={2}
        >
          <Grid
            item
            sm={12}
            md={12}
            justifyContent="center"
            alignItems="center"
          >
            <Box display="flex" ml={5} mt={1} alignItems="center">
              <Typography style={{ fontWeight: "bold" }} variant="body1">
                Driver owner
              </Typography>
              <Checkbox
                checked={iAmDriver}
                onChange={() => setIAmDriver(!iAmDriver)}
                color="primary"
              />
            </Box>
          </Grid>

          {iAmDriver && (
            <Grid
              item
              container
              sm={12}
              md={12}
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item sm={12} md={5}>
                <TextInput
                  fullWidth
                  label="License id"
                  validate={[required()]}
                  source="license_id"
                />
              </Grid>

              <Grid item sm={12} md={5}>
                <FileInput
                  label={
                    <Typography style={{ fontWeight: "bold" }}>
                      Driving License File
                    </Typography>
                  }
                  maxSize={500000}
                  validate={[required()]}
                  source="driving_license_file"
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </Grid>
            </Grid>
          )}

          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Profile Picture
                </Typography>
              }
              source="photo_file"
              maxSize={500000}
              accept="image/*"
              validate={[required()]}
            >
              <ImageField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              maxSize={500000}
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Residential ID File
                </Typography>
              }
              source="residential_id_file"
              validate={[required()]}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              maxSize={500000}
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Business License File
                </Typography>
              }
              source="business_license_file"
              validate={[required()]}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              maxSize={500000}
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Tin Certificate File
                </Typography>
              }
              source="tin_certificate_file"
              validate={[required()]}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              maxSize={500000}
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Commercial Registration Certificate File
                </Typography>
              }
              source="commercial_registration_certificate_file"
              validate={[required()]}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          spacing={2}
          justifyContent="space-around"
          container
        >
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="first_name" validate={[required()]} />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="middle_name" validate={[required()]} />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="last_name" validate={[required()]} />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="user_name" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="email" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="phone" validate={required()} />
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              fullWidth
              source="gender"
              validate={[required()]}
              choices={[
                { id: "Male", name: "Male" },
                { id: "Female", name: "Female" },
              ]}
            />
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
