import {
  Box,
  Card,
  Grid,
  Button,
  Typography,
  Divider,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Chip,
  Avatar,
  CircularProgress,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  TextField as MuiTextField,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  // ShowButton,
  TextInput,
  ImageField,
  required,
  ArrayField,
  SelectInput,
  FormDataConsumer,
  DateField,
  ReferenceInput,
  ReferenceField,
  Show,
  useListContext,
  FunctionField,
  useNotify,
  useRefresh,
  useRedirect,
  TopToolbar,
  ListButton,
  ChipField,
  NumberField,
  useQuery,
} from "react-admin";
import { BASE_URL } from "../config";
import axios from "axios";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import PrintIcon from "@material-ui/icons/Print";
import { useReactToPrint } from "react-to-print";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import moment from "moment";

import SearchIcon from "@material-ui/icons/Search";
import { Form } from "react-final-form";

const PostFilterForm = () => {
  const { filterValues, setFilters } = useListContext();

  const {
    data: regions,
    loading: regionsLoading,
    error: regionsError,
  } = useQuery({
    type: "getList",
    resource: "regions",
    payload: {
      pagination: { page: -1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    },
  });

  const {
    data: codes,
    loading: codesLoading,
    error: codesError,
  } = useQuery({
    type: "getList",
    resource: "codes",
    payload: {
      pagination: { page: -1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    },
  });

  React.useEffect(() => {
    console.log({ regions, codes });
  });

  // if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {regions?.length > 0 &&
              codes?.length > 0 &&
              !(regionsLoading || codesLoading) && (
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Box component="span" mr={2}>
                    <TextInput
                      resettable
                      helperText={false}
                      variant="outlined"
                      fullWidth
                      source="plate_number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment>
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable
                      helperText={false}
                      variant="outlined"
                      defaultValue="3"
                      fullWidth
                      source="code"
                      choices={codes}
                    />
                  </Box>
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable
                      helperText={false}
                      variant="outlined"
                      defaultValue="AA"
                      fullWidth
                      source="region"
                      choices={regions}
                    />
                  </Box>
                  <Box component="span" mr={1} mb={1.5}>
                    <Button color="primary" type="submit">
                      Search
                    </Button>
                  </Box>
                  <Box component="span" mb={1.5}>
                    <Button onClick={resetFilter}>Reset</Button>
                  </Box>
                </Box>
              )}
            {(regionsLoading || codesLoading) && (
              <Box display="flex" justifyContent="center" p={1}>
                <CircularProgress color="primary" />
              </Box>
            )}
          </form>
        )}
      </Form>
    </div>
  );
};

const ListActions = () => (
  <Box width="100%" display="flex" justifyContent="space-between" m={1} mt={2}>
    <PostFilterForm />
  </Box>
);

export const InspectionList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    actions={<ListActions />}
    sort={{ field: "created_at", order: "DESC" }}
    empty={false}
    {...props}
  >
    <Datagrid size="medium" padding="normal" rowClick="show">
      <FunctionField
        label="Full Name"
        render={(record) => (
          <Box display="flex" alignItems="center">
            <Avatar
              src={record?.inspector?.profile_picture}
              style={{
                width: parseInt(25, 16),
                height: parseInt(25, 16),
                marginRight: 8,
                marginTop: -4,
                marginBottom: -4,
              }}
            />
            <Typography>
              {record?.inspector?.first_name} {record?.inspector?.middle_name}
            </Typography>
          </Box>
        )}
      />
      {permissions?.some((val) =>
        [
          "SUPER-ADMIN",
          "SYSTEM-CLERK",
          "COMPANY-ADMIN",
          "COMPANY-CLERK",
        ].includes(val)
      ) && (
        <ReferenceField source="garage_id" reference="garages">
          <TextField source="name" />
        </ReferenceField>
      )}

      {permissions?.some((val) =>
        [
          "SUPER-ADMIN",
          "SYSTEM-CLERK",
          "COMPANY-ADMIN",
          "COMPANY-CLERK",
        ].includes(val)
      ) ? (
        <ReferenceField
          source="vehicle.id"
          label="Vehicle"
          reference="vehicles"
        >
          <TextField source="plate_number" label="Plate No." link="show" />
        </ReferenceField>
      ) : (
        <TextField source="vehicle.plate_number" label="Plate No." />
      )}

      <DateField source="created_at" label="Date" />

      <TextField source="configuration.vehicle_type" />
      <ReferenceField
        label="Version"
        source="configuration.id"
        reference="configurations"
        link="show"
      >
        <TextField source="version" />
      </ReferenceField>
      <NumberField source="score" />
      <ChipField source="grade" />

      <FunctionField
        label="Status"
        sortBy="status"
        render={(record) => (
          <Alert
            severity={
              record?.status === "PENDING"
                ? "info"
                : record?.status === "APPROVED"
                ? "success"
                : "error"
            }
          >
            <Typography variant="body2">{record?.status}</Typography>
          </Alert>
        )}
      />
      {/* <ShowButton /> */}
    </Datagrid>
  </List>
);

export const InspectionShow = ({ permissions, ...props }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const PointsGrid = () => {
    const { ids, data } = useListContext();
    return ids && ids?.length > 0 ? (
      <Box>
        {ids?.map((id) => (
          <Accordion
            key={id}
            expanded={expanded === id}
            onChange={handleChange(id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container spacing={4} style={{ width: "100%" }}>
                <Grid xs={4} item>
                  <Box flex={1}>
                    <Typography
                      style={{ fontSize: "10px", margin: 0, padding: 0 }}
                      variant="body2"
                      color="textPrimary"
                    >
                      Point Name
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {data[id].name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box flex={1}>
                    <Typography
                      style={{ fontSize: "10px", margin: 0, padding: 0 }}
                      variant="body2"
                      color="textPrimary"
                    >
                      Selection
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {data[id]?.multi_select
                        ? "Multiple Select"
                        : "Single Select"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box flex={1}>
                    <Typography
                      style={{ fontSize: "10px", margin: 0, padding: 0 }}
                      variant="body2"
                      color="textPrimary"
                    >
                      Number of Cases
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {data[id]?.cases?.length}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <ArrayField label="Cases" record={data[id]} source="cases">
                <CasesGrid />
              </ArrayField>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    ) : (
      <></>
    );
  };

  const CasesGrid = () => {
    const { ids, data } = useListContext();
    return ids && ids?.length > 0 ? (
      <Box mx={2} flex={1}>
        {ids?.map((id) => (
          <Grid key={id} container spacing={4} style={{ width: "100%" }}>
            <Grid xs={7} item>
              <Box flex={1}>
                <Typography
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    padding: 0,
                  }}
                  variant="body2"
                  color="textPrimary"
                >
                  Case Name
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {data[id]?.name}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={5} item>
              <Box ml={4} flex={1}>
                <Typography
                  style={{
                    fontSize: "10px",
                    margin: 0,
                    padding: 0,
                  }}
                  variant="body2"
                  color="textPrimary"
                >
                  Score
                </Typography>
                <Typography variant="body1" color="textPrimary">
                  {data[id]?.score}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    ) : (
      <></>
    );
  };

  const handleClick = (operator, id, reason) => {
    const { access_token } = JSON.parse(localStorage.getItem("auth"));
    if (!reason) {
      return false;
    }

    return axios({
      method: "patch",
      url: `${BASE_URL}/inspections/${id}/${operator}`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: { reason },
    })
      .then(({ data }) => {
        console.log({ data: data?.data });
        // notify(data?.data);
        redirect(`/inspections`);
        refresh();
      })
      .catch((err) => {
        console.log({ err });
        notify(err?.response?.data?.errors?.title || "Network error", {
          type: "warning",
        });
      });
  };

  const printRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const onFailure = (error) => {
    notify(`Could not load post: ${error.message}`);
    redirect("/inspections");
    refresh();
  };

  return (
    <Show {...props} actions={false} onFailure={onFailure}>
      <SimpleForm toolbar={false}>
        <FormDataConsumer>
          {({ getSource, formData, scopedFormData }) => {
            return (
              <>
                <Grid
                  ref={printRef}
                  container
                  spacing={4}
                  alignItems="center"
                  style={{ width: "100%", margin: "auto" }}
                >
                  <Box
                    p={1}
                    ml={2}
                    mr={1}
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Typography variant="h3">Inspection Result</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      {/* <Box
                        mx={1}
                        color={
                          formData?.is_passed ? "success.main" : "error.main"
                        }
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h3" color="inherit" align="center">
                          {formData?.is_passed ? "Passed" : "Failed"}
                        </Typography>
                      </Box> */}
                      <Box mx={1}>
                        <Chip
                          label={
                            <Typography
                              style={{ fontWeight: "bold" }}
                              variant="body1"
                            >
                              {formData?.grade}
                            </Typography>
                          }
                        />
                      </Box>
                      <Box
                        color={
                          formData?.status === "APPROVED"
                            ? "success.main"
                            : formData?.status === "REJECTED"
                            ? "error.main"
                            : "primary.main"
                        }
                        pl={1}
                        pr={1}
                        mx={1}
                        bgcolor={
                          formData?.status === "APPROVED"
                            ? "#D5FFDC"
                            : formData?.status === "REJECTED"
                            ? "#FFDBDB"
                            : "#FFF8DB"
                        }
                      >
                        <Button
                          color="inherit"
                          startIcon={
                            formData?.status === "APPROVED" ? (
                              <ThumbUpIcon color="inherit" />
                            ) : formData?.status === "REJECTED" ? (
                              <ThumbDownIcon color="inherit" />
                            ) : (
                              <HourglassEmptyIcon color="inherit" />
                            )
                          }
                        >
                          {formData?.status}
                        </Button>
                      </Box>
                      <Box ml={1}>
                        <Button
                          color="secondary"
                          startIcon={<PrintIcon />}
                          variant="contained"
                          onClick={handlePrint}
                        >
                          Print
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Grid xs={12} md={6} item>
                    <Card>
                      <Box p={1} paddingLeft={2}>
                        <Typography variant="h3">Car Details</Typography>
                      </Box>
                      <Divider variant="middle" />
                      <Box marginLeft={2} my={1} marginBottom={2}>
                        <Grid container>
                          <Grid xs={12} md={4} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Model
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.model}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={4} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Vehicle Type
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.vehicle_type}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={4} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Color
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.color}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={4} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Plate Number
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.plate_number}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={4} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Manufacturer
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.manufacturer}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={4} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Side Number
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.side_number}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Card>
                      <Box p={1} paddingLeft={2}>
                        <Typography variant="h3">Inspection Details</Typography>
                      </Box>
                      <Divider variant="middle" />
                      <Box mx={2} my={1} marginBottom={2}>
                        <Grid container>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Score
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.score}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Status
                            </Typography>
                            <Box
                              color={
                                formData?.is_passed
                                  ? "success.main"
                                  : "error.main"
                              }
                            >
                              <Typography
                                variant="body1"
                                style={{ fontWeight: "bold" }}
                                // color="inherit"
                                // align="center"
                              >
                                {formData?.is_passed ? "Passed" : "Failed"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Inspected on
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {moment(formData?.created_at).format("llll")}
                            </Typography>
                          </Grid>
                          {formData?.status !== "PENDING" && (
                            <Grid xs={12} md={6} item>
                              <Typography
                                style={{
                                  fontSize: "10px",
                                  margin: 0,
                                  padding: 0,
                                }}
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                Approved at
                              </Typography>
                              <Typography variant="body1" color="textPrimary">
                                {moment(formData?.updated_at).format("llll")}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid xs={12} md={6} item>
                    <Card>
                      <Box p={1} paddingLeft={2}>
                        <Typography variant="h3">Driver 1 Details</Typography>
                      </Box>
                      <Divider variant="middle" />
                      <Box mx={2} my={1} marginBottom={2}>
                        <Grid container>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Full Name
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.drivers?.[0]?.first_name +
                                " " +
                                formData?.vehicle?.drivers?.[0]?.middle_name +
                                " " +
                                formData?.vehicle?.drivers?.[0]?.last_name}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Email
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.drivers?.[0]?.email}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Phone
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.drivers?.[0]?.phone}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Gender
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.drivers?.[0]?.gender}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid xs={12} md={6} item>
                    {formData?.vehicle?.drivers?.[1] && (
                      <Card>
                        <Box p={1} paddingLeft={2}>
                          <Typography variant="h3">Driver 2 Details</Typography>
                        </Box>
                        <Divider variant="middle" />
                        <Box mx={2} my={1} marginBottom={2}>
                          <Grid container>
                            <Grid xs={12} md={6} item>
                              <Typography
                                style={{
                                  fontSize: "10px",
                                  margin: 0,
                                  padding: 0,
                                }}
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                Full Name
                              </Typography>
                              <Typography variant="body1" color="textPrimary">
                                {formData?.vehicle?.drivers?.[1]?.first_name +
                                  " " +
                                  formData?.vehicle?.drivers?.[1]?.middle_name +
                                  " " +
                                  formData?.vehicle?.drivers?.[1]?.last_name}
                              </Typography>
                            </Grid>
                            <Grid xs={12} md={6} item>
                              <Typography
                                style={{
                                  fontSize: "10px",
                                  margin: 0,
                                  padding: 0,
                                }}
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                Email
                              </Typography>
                              <Typography variant="body1" color="textPrimary">
                                {formData?.vehicle?.drivers?.[1]?.email}
                              </Typography>
                            </Grid>
                            <Grid xs={12} md={6} item>
                              <Typography
                                style={{
                                  fontSize: "10px",
                                  margin: 0,
                                  padding: 0,
                                }}
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                Phone
                              </Typography>
                              <Typography variant="body1" color="textPrimary">
                                {formData?.vehicle?.drivers?.[1]?.phone}
                              </Typography>
                            </Grid>
                            <Grid xs={12} md={6} item>
                              <Typography
                                style={{
                                  fontSize: "10px",
                                  margin: 0,
                                  padding: 0,
                                }}
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                Gender
                              </Typography>
                              <Typography variant="body1" color="textPrimary">
                                {formData?.vehicle?.drivers?.[1]?.gender}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    )}
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Card>
                      <Box p={1} paddingLeft={2}>
                        <Typography variant="h3">Owner Details</Typography>
                      </Box>
                      <Divider variant="middle" />
                      <Box marginLeft={2} my={1} marginBottom={2}>
                        <Grid container>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Full Name
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.owner?.first_name +
                                " " +
                                formData?.vehicle?.owner?.middle_name +
                                " " +
                                formData?.vehicle?.owner?.last_name}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Email
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.owner?.email}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Phone
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.owner?.phone}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Gender
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.vehicle?.owner?.gender}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Card>
                      <Box p={1} paddingLeft={2}>
                        <Typography variant="h3">Inspector Details</Typography>
                      </Box>
                      <Divider variant="middle" />
                      <Box mx={2} my={1} marginBottom={2}>
                        <Grid container>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Full Name
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.inspector?.first_name +
                                " " +
                                formData?.inspector?.middle_name +
                                " " +
                                formData?.inspector?.last_name}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Email
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.inspector?.email}
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={6} item>
                            <Typography
                              style={{
                                fontSize: "10px",
                                margin: 0,
                                padding: 0,
                              }}
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              Phone
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {formData?.inspector?.phone}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>

                  <Grid xs={12} item>
                    <Card>
                      <Box p={1} paddingLeft={2}>
                        <Typography variant="h3">Vehicle Pictures</Typography>
                      </Box>
                      <Divider variant="middle" />
                      <Box mx={2} my={1} marginBottom={2}>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-evenly"
                        >
                          <Grid xs={12} md={5} item>
                            <Typography variant="h3">Front</Typography>
                            <ImageField source="front_image_path" />
                          </Grid>
                          <Grid xs={12} md={5} item>
                            <Typography variant="h3">Back</Typography>
                            <ImageField source="back_image_path" />
                          </Grid>
                          <Grid xs={12} md={5} item>
                            <Typography variant="h3">Left</Typography>
                            <ImageField source="left_image_path" />
                          </Grid>
                          <Grid xs={12} md={5} item>
                            <Typography variant="h3">Right</Typography>
                            <ImageField source="right_image_path" />
                          </Grid>
                        </Grid>
                      </Box>
                    </Card>
                  </Grid>
                  {formData?.fail_points && (
                    <Grid xs={12} item>
                      <Card>
                        <Box p={1} paddingLeft={2}>
                          <Typography color="error" variant="h3">
                            Failed inspection points
                          </Typography>
                        </Box>
                        <Divider variant="middle" />
                        <ArrayField fullWidth source="fail_points">
                          <PointsGrid />
                        </ArrayField>
                      </Card>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={4}
                  alignItems="center"
                  style={{ width: "100%", margin: "auto" }}
                >
                  <Grid xs={12} item>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignSelf="center"
                    >
                      <Box>
                        <Typography variant="h3" gutterBottom>
                          Owner Signature
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          gutterBottom
                        >
                          {formData?.vehicle?.owner?.first_name +
                            " " +
                            formData?.vehicle?.owner?.middle_name +
                            " " +
                            formData?.vehicle?.owner?.last_name}
                        </Typography>
                        <ImageField source="sign_path" />
                      </Box>
                      {formData?.status === "PENDING" &&
                        permissions?.some((val) =>
                          ["GARAGE-ADMIN"].includes(val)
                        ) && (
                          <Box display="flex" alignItems="center">
                            <Box color="success.main">
                              <Button
                                color="inherit"
                                startIcon={<ThumbUpIcon color="inherit" />}
                                onClick={() => {
                                  setOpen("approve");
                                }}
                              >
                                Approve
                              </Button>
                            </Box>
                            <Box px={4} />
                            <Box color="error.main">
                              <Button
                                color="inherit"
                                startIcon={<ThumbDownIcon color="inherit" />}
                                onClick={() => {
                                  setOpen("reject");
                                }}
                              >
                                Reject
                              </Button>
                            </Box>
                            <Dialog
                              open={!!open}
                              onClose={() => setOpen(false)}
                              aria-labelledby="form-dialog-title"
                            >
                              <DialogTitle id="form-dialog-title">
                                Reason
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText>
                                  To approve inspection you need to provide a
                                  reason!
                                </DialogContentText>
                                <Box p={2} />
                                <MuiTextField
                                  autoFocus
                                  margin="dense"
                                  id="name"
                                  value={reason}
                                  onChange={(e) => setReason(e.target.value)}
                                  label="Reason"
                                  fullWidth
                                />
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => setOpen(false)}
                                  color="primary"
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    if (reason) {
                                      handleClick(open, formData?.id, reason);
                                      setOpen(false);
                                      setReason();
                                    }
                                  }}
                                  color="primary"
                                >
                                  Send
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Box>
                        )}
                    </Box>
                  </Grid>
                </Grid>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Show>
  );
};
