import * as React from "react";
import { stringify } from "query-string";
import { BASE_URL } from "../config";
import axios from "axios";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import { InspectionLogo } from "../assets/InspectionLogo";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  Grid,
  Paper,
  useMediaQuery,
  Button,
  Dialog,
  Select,
  Box,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
  MenuItem,
  TextField as MyTextField,
  Typography,
  Avatar,
  CircularProgress,
  makeStyles,
  Divider,
  Slider,
} from "@material-ui/core";

import {
  List,
  EmailField,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  EditButton,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  required,
  PasswordInput,
  SelectInput,
  FormDataConsumer,
  useCreate,
  FunctionField,
  ReferenceInput,
  SelectField,
  ExportButton,
  CreateButton,
  TopToolbar,
  ChipField,
  NullableBooleanInput,
  FileInput,
  FileField,
  NumberField,
  useListContext,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";

const GRADES = ["F", "D", "C", "B", "A"];

const GradeEdit = (props) => {
  const { access_token } = JSON.parse(localStorage.getItem("auth"));
  const { data, ids, loaded, loading } = useListContext();

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  React.useEffect(() => {
    const val = ids.map((id) => {
      return data[id]?.to;
    });
    val?.pop();
    setValue(val);
  }, [loaded]);

  const [show, setShow] = React.useState();
  const [value, setValue] = React.useState();

  const editGrades = () => {
    const data = value;
    data.push(100);
    const result = data.map((item, i) => {
      return { id: GRADES[i], from: i > 0 ? data?.[i - 1] : 0, to: item };
    });
    // return;
    axios({
      method: "patch",
      url: `${BASE_URL}/grades`,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      data: result,
    })
      .then((data) => {
        // console.log({ data: data?.data });
        setShow(false);
        notify("Successfuly updated grades!");
        redirect("/grades");
        refresh();
      })
      .catch((err) => {
        console.log({ err });
        notify(err?.response?.data?.errors?.title || "Network error", {
          type: "warning",
        });
      });
  };

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 100,
      label: "100",
    },
  ];

  return loading ? (
    <Box display="flex" flex={1} justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : (
    <TopToolbar {...props}>
      <Button
        onClick={() => {
          setShow(true);
        }}
        color="primary"
        variant="contained"
        size="small"
        label="Please attach a role!"
      >
        Edit grade
      </Button>
      <ExportButton />
      <Dialog
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit grade</DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            <Grid xs={12} item>
              <Box pt={5} pb={1} my={4} mx="auto" width={500}>
                <Slider
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  track={false}
                  min={0}
                  max={100}
                  color="secondary"
                  defaultValue={value}
                  marks={marks}
                  valueLabelDisplay="on"
                  valueLabelFormat={(x, y) => {
                    const GRADES = ["F", "D", "C", "B", "A"];
                    return (
                      <Box
                        width={"40px"}
                        display="flex"
                        justifyContent="space-between"
                        marginTop={1}
                        color="white"
                      >
                        <Chip
                          style={{
                            transform: `translate(-${
                              (x - (y > 0 ? value?.[y - 1] : 0)) * 2
                            }px, 30px)`,
                          }}
                          label={GRADES[y]}
                          size="small"
                        />
                        <Typography
                          color="inherit"
                          style={{ transform: "translate(-13px, -2px)" }}
                        >
                          {x}
                        </Typography>
                        {y === value?.length - 1 && (
                          <Chip
                            style={{
                              transform: `translate(${
                                (100 - x - 10) * 2
                              }px, 30px)`,
                            }}
                            label={GRADES[y + 1]}
                            size="small"
                          />
                        )}
                      </Box>
                    );
                  }}
                />
              </Box>
            </Grid>

            <Grid container xs={6} justifyContent="space-between" item>
              <Grid xs={5} item>
                <Button
                  style={{
                    paddingBottom: 8,
                    paddingTop: 8,
                    marginBottom: 16,
                    marginTop: 8,
                  }}
                  fullWidth
                  variant="contained"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid xs={5} item>
                <Button
                  style={{
                    paddingBottom: 8,
                    paddingTop: 8,
                    marginBottom: 16,
                    marginTop: 8,
                  }}
                  variant="contained"
                  fullWidth
                  onClick={editGrades}
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

const GradeGrid = (props) => {
  const classes = useStyles(props);
  const { data, ids } = useListContext();
  return ids ? (
    <Grid container spacing={3} className={classes.root}>
      {ids.map((id) => (
        <Grid key={id} xs={12} sm={6} md={4} lg={3} xl={2} item>
          <Paper elevation={2}>
            <Box
              pb={2}
              pt={3}
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h3">{data[id]?.id}</Typography>
              <Typography>
                {data[id]?.from} - {data[id]?.to}
              </Typography>
            </Box>
            <Box mx={3} py={3} className={classes.body}>
              <Box display="flex" alignItems="center">
                <DriveEtaIcon fontSize="small" color="secondary" />
                <Box pl={1} />
                <Typography>{data[id]?.vehicles} Vehicles</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <InspectionLogo
                  // style={{ marginLeft: 5, backgroundColor: "yellow" }}
                  fontSize="small"
                  color="secondary"
                />
                <Box pl={1} />
                <Typography>{data[id]?.inspections} Inspections</Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  ) : null;
};

export const GradeList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      // sort={{ field: "id", order: "ASC" }}
      page={-1}
      pagination={false}
      component="div"
      sort={{ field: "created_at", order: "DESC" }}
      {...(permissions?.some((val) =>
        ["SUPER-ADMIN", "COMPANY-ADMIN"].includes(val)
      ) ? {actions: <GradeEdit permissions={permissions} {...props} />} : {})}
      // actions={<GradeEdit permissions={permissions} {...props} />}
    >
      <GradeGrid />
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  body: {
    borderWidth: 0,
    borderTopWidth: "3px",
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
}));
