import * as React from "react";
import {
  Box,
  TextField,
  Grid,
  Card,
  Paper,
  CardHeader,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { DateRangePicker, LocalizationProvider } from "@material-ui/pickers";
import Chart from "react-google-charts";
import MomentUtils from "@date-io/moment";
import { GarageLogo } from "./assets/GarageLogo";
import SettingsIcon from "@material-ui/icons/Settings";
import { InspectionLogo } from "./assets/InspectionLogo";
import { BASE_URL } from "./config";
import { stringify } from "query-string";
import axios from "axios";
import moment from "moment";
import { useQuery, useRedirect } from "react-admin";

const Dashboard = ({ permissions, ...rest }) => {
  const { access_token } = JSON.parse(localStorage.getItem("auth"));
  const redirect = useRedirect();
  console.log({ rest });

  if (
    permissions &&
    permissions?.some((val) =>
      ["SUPER-ADMIN", "COMPANY-ADMIN", "GARAGE-ADMIN"].includes(val)
    ) !== true
  ) {
    redirect("/users");
  }

  const [value, setValue] = React.useState([
    moment().subtract(7, "weeks"),
    moment(),
  ]);

  const [barData, setBarData] = React.useState([
    ["Time", "Passes", "Fails"],
    [0, 0, 0],
  ]);

  const [lineData, setLineData] = React.useState([
    ["Time", "Garage"],
    [0, 0],
  ]);

  const [data, setData] = React.useState();

  // const { data, loading, error } = useQuery({
  //   type: "getList",
  //   resource: "users",
  //   payload: { pagination: { page: -1 },  }
  //   // payload: {
  //   //   page: -1,
  //   //   from: moment.utc(value?.[0]).add(6, "hours").format(),
  //   //   to: moment.utc(value?.[1]).add(6, "hours").format(),
  //   // },
  // });

  React.useEffect(() => {
    console.log({ value });

    axios
      .get(
        `${BASE_URL}/report?${stringify({
          page: -1,
          perPage: 10,
          filter: JSON.stringify({
            from: moment.utc(value?.[0]).add(6, "hours").format(),
            to: moment.utc(value?.[1]).add(6, "hours").format(),
          }),
        })}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((data) => {
        console.log({ data: data?.data?.data });
        setData(data?.data?.data);
        let val = data?.data?.data?.inspections;
        const res = val.map((item, i) => {
          if (i === 0) {
            return item;
          } else {
            console.log(new Date(moment.utc(item?.[0])));
            return [new Date(moment.utc(item?.[0])), ...item?.slice(1)];
          }
        });

        let value = data?.data?.data?.garage_inspections?.[0];
        let sorted = data?.data?.data?.garage_inspections
          ?.slice(1)
          ?.sort((a, b) => {
            return moment(a?.[0])?.diff(moment(b?.[0]));
          });
        // value.push(sorted);
        sorted?.unshift(value);
        // const result = sorted.map((item, i) => {
        //   if (i === 0) {
        //     return item;
        //   } else {
        //     console.log(new Date(moment.utc(item?.[0])));
        //     return [new Date(moment(item?.[0])), ...item?.slice(1)];
        //   }
        // });

        console.log({ res });

        setBarData(res);
        // setLineData(result);
      });
  }, [value]);

  const TopCards = () => {
    return (
      <Grid container spacing={1}>
        <Grid item md={4} style={{ width: "100%" }}>
          <Card>
            <Box p={1.5}>
              <Box mb={2} color="#1D1A2F" fontSize="1rem" fontWeight={800}>
                Date Filter
              </Box>
              <DateRangePicker
                calendars={2}
                value={value}
                onChange={(newValue) => setValue(newValue)}
                shouldDisableDate={(day) => {
                  console.log("hello");
                  if (
                    moment(day).isAfter(moment(value?.[0])) &&
                    moment(day).isBefore(moment(value?.[0]).add(7, "days"))
                  ) {
                    return true;
                  } else if (
                    moment(day).isAfter(
                      moment(value?.[1]).subtract(7, "days")
                    ) &&
                    moment(day).isBefore(moment(value?.[1]))
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} helperText="" />
                    <Box m={1}></Box>
                    <TextField {...endProps} helperText="" />
                  </React.Fragment>
                )}
              />
            </Box>
          </Card>
        </Grid>
        <Grid container item md={8} spacing={1} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Card style={{ background: "#1D1A2F", color: "#fff" }}>
              <Box p={2}>
                <Box display="flex" alignItems="center">
                  <GarageLogo fontSize="large" />
                  <Box ml={1} fontSize="1.1rem" fontWeight={500}>
                    {"Garages"}
                  </Box>
                </Box>
                <Box mt={1} width="100%" textAlign="left">
                  <Box mt={-1} fontWeight={600} fontSize="2.2rem">
                    {data?.garages_count || 0}
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card style={{ background: "#1D1A2F", color: "#fff" }}>
              <Box p={2}>
                <Box display="flex" alignItems="center">
                  <InspectionLogo fontSize="large" />
                  <Box ml={1} fontSize="1.1rem" fontWeight={500}>
                    {"Inspections"}
                  </Box>
                </Box>
                <Box mt={1} width="100%" textAlign="left">
                  <Box mt={-1} fontWeight={600} fontSize="2.2rem">
                    {data?.inspections_count || 0}
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card style={{ background: "#1D1A2F", color: "#fff" }}>
              <Box p={2}>
                <Box display="flex" alignItems="center">
                  <SettingsIcon fontSize="large" />
                  <Box ml={1} fontSize="1.1rem" fontWeight={500}>
                    {"Revenue"}
                  </Box>
                </Box>
                <Box mt={1} width="100%" textAlign="left">
                  <Box mt={-1} fontWeight={600} fontSize="2.2rem">
                    {data?.revenue || 0}
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const InspectionChart = () => {
    return (
      <>
        <Chart
          // width={"100%"}
          height={"500px"}
          chartType="Bar"
          loader={
            <Box
              display="flex"
              height="500px"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          }
          data={barData}
          options={{
            colors: ["#3E7949", "#D02536"],
            legend: {
              position: "none",
            },
            hAxis: {
              title: "Time",
            },
            vAxis: {
              title: "Number of Inspections",
            },
          }}
          style={{ display: "flex", flex: 1 }}
        />
        <Box display="flex" mt={1} justifyContent="center">
          <Box display="flex" width={140} justifyContent="space-evenly">
            <Box borderRadius={20} width={20} height={20} bgcolor="#3E7949" />
            <Typography>Passed</Typography>
            <Typography variant="h3">
              {barData?.slice(1).reduce((acc, cur) => acc + cur?.[1], 0)}
            </Typography>
          </Box>
          <Box display="flex" width={140} justifyContent="space-evenly">
            <Box borderRadius={20} width={20} height={20} bgcolor="#D02536" />
            <Typography>Failed</Typography>
            <Typography variant="h3">
              {barData?.slice(1).reduce((acc, cur) => acc + cur?.[2], 0)}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };

  const GaragesChart = () => {
    return (
      <Chart
        // width={"100%"}
        height={"500px"}
        chartType="Line"
        loader={
          <Box
            display="flex"
            height="500px"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        }
        data={lineData}
        options={{
          legend: {
            // position: "left",
          },
          hAxis: {
            title: "Time",
          },
          vAxis: {
            title: "Number of Inspections",
          },
          // curveType: "function",
        }}
        rootProps={{ "data-testid": "4" }}
      />
    );
  };

  const InspectionCard = () => {
    return (
      <CardHeader
        avatar={
          <Box
            style={{
              borderRadius: 50,
              padding: 10,
              backgroundColor: "#1D1A2F",
              color: "#fff",
            }}
          >
            <Box justifyContent={"center"} style={{ margin: "0 auto" }}>
              <InspectionLogo />
            </Box>
          </Box>
        }
        action={<Typography variant="body2"> 2 min ago</Typography>}
        title={<Typography variant="h6">Nano Garage</Typography>}
        subheader="September 14, 2016"
      />
    );
  };

  const ConfidurationList = () => {
    return (
      <>
        <Box
          display={"flex"}
          m={2}
          p={2}
          style={{ backgroundColor: "#f0f0f0" }}
        >
          <Typography style={{ flexGrow: 1 }}>{"Motor"}</Typography>
          <Typography>{"10"}</Typography>
        </Box>
      </>
    );
  };

  return permissions?.some((val) =>
    ["SUPER-ADMIN", "COMPANY-ADMIN", "GARAGE-ADMIN"].includes(val)
  ) ? (
    <Card>
      <LocalizationProvider dateAdapter={MomentUtils}>
        <Box p={2}>
          <TopCards />
          <Box pt={2} />
          <Paper>
            <Box p={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                  <Box pb={3} fontSize="1.5rem" fontWeight={600}>
                    Inspection
                  </Box>
                  <InspectionChart />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Box pt={2} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Paper>
                {/* <Box p={2}>
                  <Box pb={2} fontSize="1.5rem" fontWeight={600}>
                    Garages
                  </Box>
                  <GaragesChart />
                </Box> */}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </LocalizationProvider>
    </Card>
  ) : null;
};

export default Dashboard;
