import * as React from "react";
import {
  useRecordContext,
  useRefresh,
  useRedirect,
  usePermissions,
} from "react-admin";
import {
  Box,
  Switch,
  Avatar,
  Typography,
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useMutation } from "react-admin";
import { Field } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}));

export const AvatarField = ({ source, size = "25", ...props }) => {
  const record = useRecordContext();
  const classes = useStyles();
  return record ? (
    <Avatar
      src={record?.[source]}
      style={{ width: parseInt(size, 16), height: parseInt(size, 16) }}
      className={classes.avatar}
    />
  ) : null;
};

export const FullNameField = ({ source, name = "photo", size, ...props }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const first_name = "first_name";
  return record ? (
    <div className={classes.root}>
      <Avatar
        src={record?.[source][name]}
        style={{ width: parseInt(size, 16), height: parseInt(size, 16) }}
        className={classes.avatar}
      />
      {record?.[source][first_name]} {record?.[source].middle_name}
    </div>
  ) : null;
};

export const FullName = ({ source }) => {
  const record = useRecordContext();
  return (
    record && (
      <Box>
        <Typography variant="body2">{`${record?.[source]} ${record["middle_name"]} ${record["last_name"]}`}</Typography>
        <Typography variant="body2">{`${record?.email}`}</Typography>
      </Box>
    )
  );
};

export const StatusDisplay = ({ source }) => {
  const record = useRecordContext();
  return (
    record &&
    (record?.[source] === true ? (
      <Alert severity="success">
        <Typography variant="body1">Passed</Typography>
      </Alert>
    ) : (
      <Alert severity="error">
        <Typography variant="body1">Failed</Typography>
      </Alert>
    ))
  );
};

export const ChangeUserStatus = ({ source, resource }) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState();

  let role;
  if (record?.system) {
    role = record?.system?.role;
  } else if (record?.company) {
    role = record?.company?.role;
  } else if (record?.garage) {
    role = record?.garage?.role;
  }

  const [approve, { loading }] = useMutation({
    type: "update",
    resource,
    payload: {
      id: `${record?.id}/${
        record?.[source] === "INACTIVE" ? "activate" : "block"
      }`,
      data: {
        reason,
      },
    },
  });

  return (
    record && (
      <Alert
        style={{ maxWidth: 146 }}
        severity={record?.[source] === "INACTIVE" ? "error" : "success"}
        {...((permissions?.some((val) => ["SUPER-ADMIN"].includes(val)) &&
          ["COMPANY-ADMIN", "SYSTEM-CLERK"]?.includes(role)) ||
        (permissions?.some((val) => ["COMPANY-ADMIN"].includes(val)) &&
          [
            "GARAGE-ADMIN",
            "COMPANY-CLERK",
            "LEGAL-DOCUMENT-APPROVER",
            "BANK-ACCOUNT-APPROVER",
          ]?.includes(role)) ||
        (permissions?.some((val) => ["GARAGE-ADMIN"].includes(val)) &&
          ["GARAGE-CLERK", "INSPECTOR"]?.includes(role))
          ? {
              action: (
                <Switch
                  checked={record?.[source] === "INACTIVE" ? false : true}
                  onChange={() => setOpen(true)}
                  disabled={loading}
                  color="primary"
                />
              ),
            }
          : {})}
      >
        <Typography variant="body2">
          {record?.[source] === "INACTIVE" ? "Blocked" : "Active"}
        </Typography>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reason</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To change status you need to provide a reason!
            </DialogContentText>
            <Box p={2} />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              label="Reason"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (reason) {
                  approve();
                  setOpen(false);
                  setReason();
                }
              }}
              color="primary"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </Alert>
    )
  );
};

export const ChangeEntityStatus = ({ source, resource }) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState();

  const [approve, { loading }] = useMutation({
    type: "update",
    resource,
    payload: {
      id: `${record?.id}/${
        record?.[source] === "INACTIVE" ? "activate" : "block"
      }`,
      data: {
        reason,
      },
    },
  });

  return (
    record && (
      <Alert
        style={{ maxWidth: 146 }}
        severity={record?.[source] === "INACTIVE" ? "error" : "success"}
        {...((permissions?.some((val) => ["SUPER-ADMIN"].includes(val)) &&
          resource === "companies") ||
        (permissions?.some((val) => ["COMPANY-ADMIN"].includes(val)) &&
          resource === "garages")
          ? {
              action: (
                <Switch
                  checked={record?.[source] === "INACTIVE" ? false : true}
                  onChange={() => setOpen(true)}
                  disabled={loading}
                  color="primary"
                />
              ),
            }
          : {})}
      >
        <Typography variant="body2">
          {record?.[source] === "INACTIVE" ? "Blocked" : "Active"}
        </Typography>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reason</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To change status you need to provide a reason!
            </DialogContentText>
            <Box p={2} />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              label="Reason"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (reason) {
                  approve();
                  setOpen(false);
                  setReason();
                }
              }}
              color="primary"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </Alert>
    )
  );
};

export const ChangeStatus = ({ source, resource }) => {
  const record = useRecordContext();
  const { permissions } = usePermissions();
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState();

  const [approve, { loading }] = useMutation({
    type: "update",
    resource,
    payload: {
      id: `${record?.id}/${
        record?.[source] === "INACTIVE" ? "activate" : "block"
      }`,
      data: {
        reason,
      },
    },
  });

  return (
    record && (
      <Alert
        style={{ maxWidth: 146 }}
        severity={record?.[source] === "INACTIVE" ? "error" : "success"}
        {...(permissions?.some((val) => ["COMPANY-ADMIN"].includes(val))
          ? {
              action: (
                <Switch
                  checked={record?.[source] === "INACTIVE" ? false : true}
                  onChange={() => {
                    setOpen(true);
                  }}
                  disabled={loading}
                  color="primary"
                />
              ),
            }
          : {})}
      >
        <Typography variant="body2">
          {record?.[source] === "INACTIVE" ? "Blocked" : "Active"}
        </Typography>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reason</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To change status you need to provide a reason!
            </DialogContentText>
            <Box p={2} />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              label="Reason"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (reason) {
                  approve();
                  setOpen(false);
                  setReason();
                }
              }}
              color="primary"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </Alert>
    )
  );
};

export const ChangeConfigStatus = ({ source, resource }) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { permissions } = usePermissions();
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState();

  const [approve, { data, loading }] = useMutation({
    type: "update",
    resource,
    payload: {
      id: `${record?.id}/${
        record?.[source] === "INACTIVE" ? "activate" : "deactivate"
      }`,
      data: {
        reason,
      },
    },
  });

  React.useEffect(() => {
    if (data) {
      redirect("/configurations");
      refresh();
    }
  }, [data]);

  return (
    record && (
      <Alert
        severity={record?.[source] === "INACTIVE" ? "error" : "success"}
        style={{ maxWidth: 146 }}
        {...(record?.[source] === "INACTIVE" &&
        permissions?.some((val) => ["COMPANY-ADMIN"].includes(val))
          ? {
              action: (
                <Switch
                  checked={record?.[source] === "INACTIVE" ? false : true}
                  onChange={() => setOpen(true)}
                  disabled={loading}
                  color="primary"
                />
              ),
            }
          : {})}
      >
        <Typography variant="body2">
          {record?.[source] === "INACTIVE" ? "Inactive" : "Active"}
        </Typography>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reason</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To change status you need to provide a reason!
            </DialogContentText>
            <Box p={2} />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              label="Reason"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (reason) {
                  approve();
                  setOpen(false);
                  setReason();
                }
              }}
              color="primary"
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </Alert>
    )
  );
};

export const VehicleType = (props) => {
  console.log({ props });
  return (
    <span>
      <Field
        name="position.lat"
        component="input"
        type="number"
        placeholder="latitude"
      />
      &nbsp;
      <Field
        name="position.lon"
        component="input"
        type="number"
        placeholder="longitude"
      />
    </span>
  );
};
