import axios from "axios";
import jwt_decode from "jwt-decode";
import { BASE_URL, SSO_URL } from "./config";

const authProvider = {
  login: ({ username, password }) => {
    return axios({
      method: "post",
      url: `${SSO_URL}/login`,
      data: {
        username,
        password,
      },
    })
      .then(async ({ data }) => {
        console.log({ data });
        const role = await axios({
          method: "get",
          url: `${BASE_URL}/permissions`,
          headers: {
            Authorization: `Bearer ${data?.data?.access_token}`,
          },
        })
          .then(({ data }) => {
            return data?.data;
            // localStorage.setItem("auth", JSON.stringify(data));
          })
          .catch((err) => {
            throw new Error(
              err?.response?.data?.errors?.title || "Network error"
            );
          });
        if (role && Array.isArray(role) && role?.length > 0) {
          localStorage.setItem("auth", JSON.stringify({ ...data?.data, role }));
        } else {
          throw new Error("Not Authorized!");
        }
      })
      .catch((err) => {
        throw new Error(
          err?.response?.data?.error?.errorMessage ||
            err?.message ||
            "Network error"
        );
      });
  },

  checkError: (error) => {
    const status = error?.response?.status;
    console.log({ error }, { status });
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),

  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },

  getIdentity: () => {
    try {
      const auth = JSON.parse(localStorage.getItem("auth"));
      const { subject, first_name, middle_name, last_name, picture } =
        jwt_decode(auth?.id_token);
      return Promise.resolve({
        id: subject,
        fullName: `${first_name} ${middle_name} ${last_name}`,
        avatar: picture,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getPermissions: () => {
    const auth = JSON.parse(localStorage.getItem("auth"));

    if (!auth) {
      return Promise.reject();
    }

    // return axios({
    //   method: "get",
    //   url: `${BASE_URL}/permissions`,
    //   headers: {
    //     Authorization: `Bearer ${auth?.access_token}`,
    //   },
    // })
    //   .then(({ data }) => {
    //     console.log({ data });
    //     return Promise.resolve(data?.data);
    //     // localStorage.setItem("auth", JSON.stringify(data));
    //   })
    //   .catch((err) => {
    //     return Promise.reject();
    //     // throw new Error(err?.response?.data?.errors?.title || "Network error");
    //   });
    return auth ? Promise.resolve(auth?.role) : Promise.reject();
  },
};

export default authProvider;
