import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  DateField,
  FunctionField,
  TextInput,
} from "react-admin";

// material-ui
import { makeStyles, Box, Typography, Chip, Avatar } from "@material-ui/core";

import ReactJson from "react-json-view";

const useStyles = makeStyles((theme) => ({
  jsonData: {
    maxWidth: 400,
    minWidth: 100,
  },
  date: {
    maxWidth: 100,
  },
}));

export const ActivityLogList = (props) => {
  const classes = useStyles();
  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={[<TextInput fullWidth label="Search" source="q" alwaysOn />]}
      empty={false}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid>
        <FunctionField
          label="Full Name"
          render={(record) => (
            <Box display="flex" alignItems="center">
              <Avatar
                src={record?.user?.profile_picture}
                style={{
                  width: parseInt(25, 16),
                  height: parseInt(25, 16),
                  marginRight: 8,
                  marginTop: -4,
                  marginBottom: -4,
                }}
              />
              <Typography>
                {record?.user?.first_name} {record?.user?.middle_name}
              </Typography>
            </Box>
          )}
        />
        <FunctionField
          label="Role"
          sortBy="role"
          render={(record) => {
            if (
              record?.role &&
              Array.isArray(record?.role) &&
              record?.role?.length > 0
            ) {
              return record?.role?.map((item) => (
                <Box pb={1}>
                  <Chip
                    style={{ maxWidth: 110, padding: 0, margin: 0 }}
                    label={item}
                  />
                </Box>
              ));
            }
          }}
        />
        <FunctionField
          label="Request Data"
          cellClassName={classes.jsonData}
          render={(record) => {
            return (
              record?.request_data && (
                <ReactJson
                  src={JSON.parse(record?.request_data)}
                  name={null}
                  enableClipboard={false}
                  collapsed={true}
                  displayDataTypes={false}
                />
              )
            );
          }}
        />
        <TextField source="action" />
        <TextField source="resource" />
        <TextField source="status_code" />
        <DateField
          cellClassName={classes.date}
          label="Date"
          showTime
          source="created_at"
        />
      </Datagrid>
    </List>
  );
};
