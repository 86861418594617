import * as React from "react";
import { stringify } from "query-string";
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  NumberInput,
  SimpleFormIterator,
  ArrayInput,
  required,
  useQuery,
  BooleanInput,
  TabbedForm,
  FormTab,
  EditButton,
  FormDataConsumer,
  FunctionField,
  EmailField,
  NullableBooleanInput,
  FileInput,
  FileField,
  DateField,
  ReferenceInput,
  SelectInput,
  DateInput,
  AutocompleteInput,
  useCreateSuggestionContext,
  useCreate,
  SelectField,
  useMutation,
  ShowButton,
  useListContext,
  CreateButton,
  Show,
  useGetList,
  ReferenceField,
} from "react-admin";

import { ChangeStatus } from "../components/CustomFields";

import {
  InputAdornment,
  IconButton,
  Box,
  CircularProgress,
  TextField as MyTextField,
  Grid,
  makeStyles,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import { BASE_URL } from "../config";
import axios from "axios";

import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import SearchIcon from "@material-ui/icons/Search";
import { Form } from "react-final-form";

const PostFilterForm = () => {
  const { filterValues, setFilters } = useListContext();

  const {
    data: regions,
    loading: regionsLoading,
    error: regionsError,
  } = useQuery({
    type: "getList",
    resource: "regions",
    payload: {
      pagination: { page: -1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    },
  });

  const {
    data: codes,
    loading: codesLoading,
    error: codesError,
  } = useQuery({
    type: "getList",
    resource: "codes",
    payload: {
      pagination: { page: -1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    },
  });

  React.useEffect(() => {
    console.log({ regions, codes });
  });

  // if (!displayedFilters.main) return null;

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {regions?.length > 0 &&
              codes?.length > 0 &&
              !(regionsLoading || codesLoading) && (
                <Box display="flex" alignItems="flex-end" mb={1}>
                  <Box component="span" mr={2}>
                    <TextInput
                      resettable
                      helperText={false}
                      variant="outlined"
                      fullWidth
                      source="plate_number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment>
                            <SearchIcon color="primary" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable
                      helperText={false}
                      variant="outlined"
                      defaultValue="3"
                      fullWidth
                      source="code"
                      choices={codes}
                    />
                  </Box>
                  <Box component="span" mr={2}>
                    <SelectInput
                      resettable
                      helperText={false}
                      variant="outlined"
                      defaultValue="AA"
                      fullWidth
                      source="region"
                      choices={regions}
                    />
                  </Box>
                  <Box component="span" mr={1} mb={1.5}>
                    <Button color="primary" type="submit">
                      Search
                    </Button>
                  </Box>
                  <Box component="span" mb={1.5}>
                    <Button onClick={resetFilter}>Reset</Button>
                  </Box>
                </Box>
              )}
            {(regionsLoading || codesLoading) && (
              <Box display="flex" justifyContent="center" p={1}>
                <CircularProgress color="primary" />
              </Box>
            )}
          </form>
        )}
      </Form>
    </div>
  );
};

const ListActions = ({ permissions }) => (
  <Box width="100%" display="flex" justifyContent="space-between" m={1} mt={2}>
    <PostFilterForm />
    {permissions?.some((val) => ["COMPANY-CLERK"].includes(val)) && (
      <CreateButton />
    )}
  </Box>
);

export const VehicleList = ({ permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    sort={{ field: "created_at", order: "DESC" }}
    {...(permissions?.some((val) =>
      ["SUPER-ADMIN", "COMPANY-ADMIN"].includes(val)
    )
      ? {
          filters: [<TextInput fullWidth label="Search" source="q" alwaysOn />],
        }
      : { actions: <ListActions permissions={permissions} /> })}
    empty={false}
    {...props}
  >
    <Datagrid size="medium" padding="normal">
      <TextField source="vehicle_type" />
      <FunctionField
        label="Plate Number"
        render={(record) =>
          `${record?.region}-${record?.code}-${record?.plate_number}`
        }
      />

      <TextField source="side_number" />
      <TextField source="production_year" />
      <ChangeStatus resource="companies" source="status" />
      <ShowButton />
      {permissions?.some((val) =>
        ["SUPER-ADMIN", "COMPANY-ADMIN", "GARAGE-ADMIN"].includes(val)
      ) && <EditButton />}
    </Datagrid>
  </List>
);

export const VehicleShow = (props) => {
  const [status, setStatus] = React.useState();

  const [approve, { loading }] = useMutation({
    type: "update",
    resource: props?.resource,
    payload: {
      id: `${props?.id}/${status ? "approve-document" : "reject-document"}`,
    },
  });

  React.useEffect(() => {
    if (status === true) {
      approve();
    } else if (status === false) {
      approve();
    }
  }, [status]);

  return (
    <Show {...props}>
      <SimpleForm toolbar={false}>
        <Grid
          style={{ width: "100%" }}
          justifyContent="space-around"
          container
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <Box py={1}>
              <FileField fullWidth source="insurance" title="Insurance File" />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <Box py={1}>
              <FileField
                fullWidth
                source="representation"
                title="Representation File"
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <Box py={1}>
              <FileField
                fullWidth
                source="vehicle_registration"
                title="Vehicle Registration File"
              />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          spacing={2}
          justifyContent="space-around"
          container
        >
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Vehicle Type
            </Typography>
            <TextField fullWidth source="vehicle_type" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Region
            </Typography>
            <TextField fullWidth source="region" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Code
            </Typography>
            <TextField fullWidth source="code" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Plate Number
            </Typography>
            <TextField fullWidth source="plate_number" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Production Year
            </Typography>
            <TextField fullWidth source="production_year" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Made in
            </Typography>
            <TextField fullWidth source="made_in" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Manufacturer
            </Typography>
            <TextField fullWidth source="manufacturer" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Color
            </Typography>
            <TextField fullWidth source="color" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Model
            </Typography>
            <TextField fullWidth source="model" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Insurance Type
            </Typography>
            <TextField fullWidth source="insurance_type" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Vin
            </Typography>
            <TextField fullWidth source="vin" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Side number
            </Typography>
            <TextField fullWidth source="side_number" />
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Document status
            </Typography>
            <SelectField
              source="legal_document_approval"
              choices={[
                { id: "PENDING", name: "Pending" },
                { id: "REJECTED", name: "Rejected" },
                { id: "APPROVED", name: "Approved" },
              ]}
            />
          </Grid>
          <Grid item sm={12} md={5}></Grid>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Owner
            </Typography>
            <ReferenceField source="owner.id" reference="owners" link="show">
              <TextField source="email" />
            </ReferenceField>
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Owner
            </Typography>
            <ReferenceField source="owner.id" reference="owners" link="show">
              <TextField source="phone" />
            </ReferenceField>
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Driver 1
            </Typography>
            <ReferenceField
              source="drivers[0].id"
              reference="drivers"
              link="show"
            >
              <TextField source="email" />
            </ReferenceField>
          </Grid>
          <Grid item sm={12} md={5}>
            <Typography
              style={{
                fontSize: "10px",
              }}
              variant="body2"
            >
              Driver 1
            </Typography>
            <ReferenceField
              source="drivers[0].id"
              reference="drivers"
              link="show"
            >
              <TextField source="phone" />
            </ReferenceField>
          </Grid>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData?.drivers?.length > 1 && (
                <>
                  <Grid item sm={12} md={5}>
                    <Typography
                      style={{
                        fontSize: "10px",
                      }}
                      variant="body2"
                    >
                      Driver 2
                    </Typography>
                    <ReferenceField
                      source="drivers[1].id"
                      reference="drivers"
                      link="show"
                    >
                      <TextField source="email" />
                    </ReferenceField>
                    {/* <TextField fullWidth source="drivers[1].email" /> */}
                  </Grid>
                  <Grid item sm={12} md={5}>
                    <Typography
                      style={{
                        fontSize: "10px",
                      }}
                      variant="body2"
                    >
                      Driver 2
                    </Typography>
                    <ReferenceField
                      source="drivers[1].id"
                      reference="drivers"
                      link="show"
                    >
                      <TextField source="phone" />
                    </ReferenceField>
                  </Grid>
                </>
              )
            }
          </FormDataConsumer>
        </Grid>
        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData?.legal_document_approval === "PENDING" &&
            props?.permissions?.some((val) =>
              ["LEGAL-DOCUMENT-APPROVER"].includes(val)
            ) && (
              <Box p={1}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignSelf="center"
                >
                  <Box color="success.main">
                    <Button
                      color="inherit"
                      startIcon={<ThumbUpIcon color="inherit" />}
                      disabled={loading}
                      onClick={() => {
                        setStatus(true);
                      }}
                    >
                      Approve
                    </Button>
                  </Box>
                  <Box px={4} />
                  <Box color="error.main">
                    <Button
                      color="inherit"
                      startIcon={<ThumbDownIcon color="inherit" />}
                      disabled={loading}
                      onClick={() => {
                        setStatus(false);
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                </Box>
              </Box>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Show>
  );
};

export const VehicleEdit = (props) => {
  const classes = useStyles();

  const [searchOwner, setSearchOwner] = React.useState();
  const [owner, setOwner] = React.useState();
  const [ownerLoading, setOwnerLoading] = React.useState();

  const [searchDriverOne, setSearchDriverOne] = React.useState();
  const [driverOne, setDriverOne] = React.useState();
  const [driverOneLoading, setDriverOneLoading] = React.useState();

  const [searchDriverTwo, setSearchDriverTwo] = React.useState();
  const [driverTwo, setDriverTwo] = React.useState();
  const [driverTwoLoading, setDriverTwoLoading] = React.useState();

  const {
    data: regions,
    loading: regionsLoading,
    error: regionsError,
  } = useQuery({
    type: "getList",
    resource: "regions",
    payload: {
      pagination: { page: -1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    },
  });

  const {
    data: codes,
    loading: codesLoading,
    error: codesError,
  } = useQuery({
    type: "getList",
    resource: "codes",
    payload: {
      pagination: { page: -1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    },
  });

  const transform = (data) => {
    const { gender, driverOne, driverTwo, drivers, ...rest } = data;

    const formData = new FormData();
    for (let key in rest) {
      if (key === "insurance_file") {
        console.log({ data_key: rest[key] });
        formData.append("insurance_file", rest[key]?.rawFile);
      } else if (key === "representation_file") {
        console.log({ data_key: rest[key] });
        formData.append("representation_file", rest[key]?.rawFile);
      } else if (key === "vehicle_registration_file") {
        console.log({ data_key: rest[key] });
        formData.append("vehicle_registration_file", rest[key]?.rawFile);
      } else if (
        typeof rest[key] === "object" &&
        !Array.isArray(rest[key]) &&
        rest[key] !== null
      ) {
        formData.append(key, JSON.stringify(rest[key]));
      } else {
        if (Array.isArray(rest[key]) === true) {
          rest[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, rest[key]);
        }
      }
    }

    formData.append("drivers", driverOne);
    formData.append("drivers", driverTwo);

    return formData;
  };

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm
        onChange={(event) => {
          event.target.value = null;
          console.log(event.vin);
        }}
      >
        <Grid
          style={{ width: "100%" }}
          container
          justifyContent="space-around"
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Insurance File
                </Typography>
              }
              source="insurance_file"
              maxSize={500000}
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.insurance_file ? null : (
                  <FileField source="insurance" title="Insurance File" />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Representation File
                </Typography>
              }
              maxSize={500000}
              source="representation_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.representation_file ? null : (
                  <FileField
                    source="representation"
                    title="Representation File"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Vehicle Registration File
                </Typography>
              }
              maxSize={500000}
              source="vehicle_registration_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return formData.vehicle_registration_file ? null : (
                  <FileField
                    source="vehicle_registration"
                    title="Vehicle Registration File"
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          container
          spacing={2}
          justifyContent="space-around"
        >
          <Grid item sm={12} md={5}>
            <ReferenceInput source="vehicle_type" reference="vehicletypes">
              <SelectInput fullWidth optionText="id" />
            </ReferenceInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              defaultValue="AA"
              fullWidth
              source="region"
              choices={regions}
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              defaultValue="3"
              fullWidth
              source="code"
              choices={codes}
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="plate_number" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="production_year" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="made_in" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="manufacturer" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="color" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="model" />
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              fullWidth
              source="insurance_type"
              choices={[
                { id: "full", name: "Full" },
                { id: "third_party", name: "Third Party" },
              ]}
            />
            {/* <TextInput fullWidth source="insurance_type" /> */}
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="vin" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth source="side_number" />
          </Grid>
          {/* <Grid item sm={12} md={5}>
            <NullableBooleanInput
              style={{ width: "272px" }}
              source="gender"
              validate={required()}
              falseLabel="Female"
              trueLabel="Male"
            />
          </Grid> */}
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                setOwner(owner);
                return (
                  <MyTextField
                    label="Add Registered Owner email or phone"
                    value={owner}
                    fullWidth
                    onChange={(event) => {
                      if (searchOwner === false) {
                        setSearchOwner(null);
                      }
                      setOwner(event.target.value);
                    }}
                    helperText={
                      searchOwner === false &&
                      "Please enter an already registered owner email or phone!"
                    }
                    defaultValue={formData?.owner?.email}
                    error={searchOwner === false}
                    InputProps={{
                      endAdornment: (
                        // <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setOwnerLoading(true);
                            const { access_token } = JSON.parse(
                              localStorage.getItem("auth")
                            );
                            axios({
                              method: "get",
                              url: `${BASE_URL}/owners?${stringify({
                                page: 1,
                                perPage: 2,
                                filter: isNaN(owner)
                                  ? JSON.stringify({ email: owner })
                                  : JSON.stringify({ phone: owner }),
                              })}`,
                              headers: {
                                Authorization: `Bearer ${access_token}`,
                              },
                            })
                              .then((data) => {
                                console.log({ data: data?.data?.data });
                                if (data?.data?.data?.length > 0) {
                                  setSearchOwner(data?.data?.data?.[0]);
                                } else {
                                  setSearchOwner(false);
                                  // setOwner("");
                                }
                              })
                              .catch((err) => {
                                console.log({ err });
                                setSearchOwner(false);
                                // setOwner("");
                              })
                              .finally(() => {
                                setOwnerLoading(false);
                              });
                          }}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {!ownerLoading ? (
                            <Search />
                          ) : (
                            <CircularProgress color="primary" size={20} />
                          )}
                        </IconButton>
                        // </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                formData.owner_id = searchOwner?.id;
                return (
                  searchOwner?.id && (
                    <TextInput
                      disabled
                      fullWidth
                      label="Owner full name"
                      format={(val) => searchOwner?.first_name}
                      validate={required()}
                      source="owner_id"
                    />
                  )
                );
              }}
            </FormDataConsumer>
          </Grid>

          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                return (
                  <MyTextField
                    label="Add Registered Driver 1 email or phone"
                    value={driverOne}
                    onChange={(event) => {
                      if (searchDriverOne === false) {
                        setSearchDriverOne(null);
                      }
                      setDriverOne(event.target.value);
                    }}
                    helperText={
                      searchDriverOne === false &&
                      "Please enter an already registered driver email or phone!"
                    }
                    fullWidth
                    defaultValue={formData?.drivers?.[0]?.email}
                    error={searchDriverOne === false}
                    InputProps={{
                      endAdornment: (
                        // <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setDriverOneLoading(true);
                            const { access_token } = JSON.parse(
                              localStorage.getItem("auth")
                            );
                            axios({
                              method: "get",
                              url: `${BASE_URL}/drivers?${stringify({
                                page: 1,
                                perPage: 2,
                                filter: isNaN(driverOne)
                                  ? JSON.stringify({ email: driverOne })
                                  : JSON.stringify({ phone: driverOne }),
                              })}`,
                              headers: {
                                Authorization: `Bearer ${access_token}`,
                              },
                            })
                              .then((data) => {
                                console.log({ data: data?.data?.data });
                                if (data?.data?.data?.length > 0) {
                                  setSearchDriverOne(data?.data?.data?.[0]);
                                } else {
                                  setSearchDriverOne(false);
                                  // setDriverOne("");
                                }
                              })
                              .catch((err) => {
                                console.log({ err });
                                setSearchDriverOne(false);
                                // setDriverOne("");
                              })
                              .finally(() => {
                                setDriverOneLoading(false);
                              });
                          }}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {!driverOneLoading ? (
                            <Search />
                          ) : (
                            <CircularProgress color="primary" size={20} />
                          )}
                        </IconButton>
                        // </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                formData.driverOne = searchDriverOne?.id;
                return (
                  searchDriverOne?.id && (
                    <TextInput
                      disabled
                      fullWidth
                      label="Driver 1 Full Name"
                      format={(val) => searchDriverOne?.first_name}
                      validate={required()}
                      source="driverOne"
                    />
                  )
                );
              }}
            </FormDataConsumer>
          </Grid>

          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                return (
                  <MyTextField
                    label="Add Registered Driver 2 email or phone"
                    value={driverTwo}
                    onChange={(event) => {
                      if (searchDriverTwo === false) {
                        setSearchDriverTwo(null);
                      }
                      setDriverTwo(event.target.value);
                    }}
                    helperText={
                      searchDriverTwo === false &&
                      "Please enter an already registered driver email or phone!"
                    }
                    fullWidth
                    defaultValue={formData?.drivers?.[1]?.email}
                    error={searchDriverTwo === false}
                    InputProps={{
                      endAdornment: (
                        // <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setDriverTwoLoading(true);
                            const { access_token } = JSON.parse(
                              localStorage.getItem("auth")
                            );
                            axios({
                              method: "get",
                              url: `${BASE_URL}/drivers?${stringify({
                                page: 1,
                                perPage: 2,
                                filter: isNaN(driverTwo)
                                  ? JSON.stringify({ email: driverTwo })
                                  : JSON.stringify({ phone: driverTwo }),
                              })}`,
                              headers: {
                                Authorization: `Bearer ${access_token}`,
                              },
                            })
                              .then((data) => {
                                console.log({ data: data?.data?.data });
                                if (data?.data?.data?.length > 0) {
                                  setSearchDriverTwo(data?.data?.data?.[0]);
                                } else {
                                  setSearchDriverTwo(false);
                                  // setDriverTwo("");
                                }
                              })
                              .catch((err) => {
                                console.log({ err });
                                setSearchDriverTwo(false);
                                // setDriverTwo("");
                              })
                              .finally(() => {
                                setDriverTwoLoading(false);
                              });
                          }}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {!driverTwoLoading ? (
                            <Search />
                          ) : (
                            <CircularProgress color="primary" size={20} />
                          )}
                        </IconButton>
                        // </InputAdornment>
                      ),
                    }}
                  />
                );
              }}
            </FormDataConsumer>
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                formData.driverTwo = searchDriverTwo?.id;
                return (
                  searchDriverTwo?.id && (
                    <TextInput
                      disabled
                      fullWidth
                      label="Driver 2 Full Name"
                      format={(val) => searchDriverTwo?.first_name}
                      validate={required()}
                      source="driverTwo"
                    />
                  )
                );
              }}
            </FormDataConsumer>
          </Grid>
          {/* <ReferenceInput
            source="owners"
            reference="owners"
            filterToQuery={(searchText) => {
              // if (searchText?.length < 12) {
              //   return;
              // }
              return isNaN(owner)
                ? { email: searchText }
                : { phone: searchText };
            }}
          >
            <AutocompleteInput optionText="email" />
          </ReferenceInput> */}
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export const VehicleCreate = (props) => {
  const classes = useStyles();
  const { access_token } = JSON.parse(localStorage.getItem("auth"));

  const [searchOwner, setSearchOwner] = React.useState();
  const [owner, setOwner] = React.useState();
  const [ownerLoading, setOwnerLoading] = React.useState();

  const [searchDriverOne, setSearchDriverOne] = React.useState();
  const [driverOne, setDriverOne] = React.useState();
  const [driverOneLoading, setDriverOneLoading] = React.useState();

  const [searchDriverTwo, setSearchDriverTwo] = React.useState();
  const [driverTwo, setDriverTwo] = React.useState();
  const [driverTwoLoading, setDriverTwoLoading] = React.useState();

  const {
    data: regions,
    loading: regionsLoading,
    error: regionsError,
  } = useQuery({
    type: "getList",
    resource: "regions",
    payload: {
      pagination: { page: -1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    },
  });

  const {
    data: codes,
    loading: codesLoading,
    error: codesError,
  } = useQuery({
    type: "getList",
    resource: "codes",
    payload: {
      pagination: { page: -1, perPage: 10 },
      sort: { field: "id", order: "DESC" },
      filter: {},
    },
  });

  const transform = (data) => {
    const { gender, driverOne, driverTwo, ...rest } = data;

    const formData = new FormData();
    for (let key in rest) {
      if (key === "insurance_file") {
        console.log({ data_key: rest[key] });
        formData.append("insurance_file", rest[key]?.rawFile);
      } else if (key === "representation_file") {
        console.log({ data_key: rest[key] });
        formData.append("representation_file", rest[key]?.rawFile);
      } else if (key === "vehicle_registration_file") {
        console.log({ data_key: rest[key] });
        formData.append("vehicle_registration_file", rest[key]?.rawFile);
      } else {
        if (Array.isArray(rest[key]) === true) {
          rest[key]?.forEach((each, i) => {
            formData.append(key, JSON.stringify(each));
          });
        } else {
          formData.append(key, rest[key]);
        }
      }
    }

    formData.append("drivers", driverOne);
    formData.append("drivers", driverTwo);

    return formData;
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm
        onChange={(event) => {
          event.target.value = null;
          console.log(event.vin);
        }}
      >
        <Grid
          style={{ width: "100%" }}
          container
          justifyContent="space-around"
          spacing={2}
        >
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Insurance File
                </Typography>
              }
              source="insurance_file"
              maxSize={500000}
              validate={[required()]}
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Representation File
                </Typography>
              }
              maxSize={500000}
              validate={[required()]}
              source="representation_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <FileInput
              label={
                <Typography style={{ fontWeight: "bold" }}>
                  Vehicle Registration File
                </Typography>
              }
              maxSize={500000}
              validate={[required()]}
              source="vehicle_registration_file"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item sm={12} md={5}></Grid>
        </Grid>

        <Grid style={{ width: "100%" }} container>
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
        </Grid>

        <Grid
          style={{ width: "100%" }}
          container
          spacing={2}
          justifyContent="space-around"
        >
          <Grid item sm={12} md={5}>
            <ReferenceInput
              validate={[required()]}
              source="vehicle_type"
              reference="vehicletypes"
            >
              <SelectInput fullWidth optionText="id" />
            </ReferenceInput>
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              defaultValue="AA"
              fullWidth
              validate={[required()]}
              source="region"
              choices={regions}
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              defaultValue="3"
              fullWidth
              validate={[required()]}
              source="code"
              choices={codes}
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput
              fullWidth
              validate={[required()]}
              source="plate_number"
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput
              fullWidth
              validate={[required()]}
              source="production_year"
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={[required()]} source="made_in" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput
              fullWidth
              validate={[required()]}
              source="manufacturer"
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={[required()]} source="color" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={[required()]} source="model" />
          </Grid>
          <Grid item sm={12} md={5}>
            <SelectInput
              fullWidth
              validate={[required()]}
              source="insurance_type"
              choices={[
                { id: "full", name: "Full" },
                { id: "third_party", name: "Third Party" },
              ]}
            />
            {/* <TextInput fullWidth validate={[required()]} source="insurance_type" /> */}
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={[required()]} source="vin" />
          </Grid>
          <Grid item sm={12} md={5}>
            <TextInput fullWidth validate={[required()]} source="side_number" />
          </Grid>
          {/* <Grid item sm={12} md={5}>
            <NullableBooleanInput
              style={{ width: "272px" }}
              source="gender"
              validate={required()}
              falseLabel="Female"
              trueLabel="Male"
            />
          </Grid> */}
          <Grid item sm={12}>
            <Box my={3}>
              <Divider />
            </Box>
          </Grid>
          <Grid item sm={12} md={5}>
            <MyTextField
              label="Add Registered Owner email or phone"
              value={owner}
              fullWidth
              onChange={(event) => {
                if (searchOwner === false) {
                  setSearchOwner(null);
                }
                setOwner(event.target.value);
              }}
              helperText={
                searchOwner === false &&
                "Please enter an already registered owner email or phone!"
              }
              error={searchOwner === false}
              InputProps={{
                endAdornment: (
                  // <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setOwnerLoading(true);
                      const { access_token } = JSON.parse(
                        localStorage.getItem("auth")
                      );
                      axios({
                        method: "get",
                        url: `${BASE_URL}/owners?${stringify({
                          page: 1,
                          perPage: 2,
                          filter: isNaN(owner)
                            ? JSON.stringify({ email: owner })
                            : JSON.stringify({ phone: owner }),
                        })}`,
                        headers: {
                          Authorization: `Bearer ${access_token}`,
                        },
                      })
                        .then((data) => {
                          console.log({ data: data?.data?.data });
                          if (data?.data?.data?.length > 0) {
                            setSearchOwner(data?.data?.data?.[0]);
                          } else {
                            setSearchOwner(false);
                            // setOwner("");
                          }
                        })
                        .catch((err) => {
                          console.log({ err });
                          setSearchOwner(false);
                          // setOwner("");
                        })
                        .finally(() => {
                          setOwnerLoading(false);
                        });
                    }}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {!ownerLoading ? (
                      <Search />
                    ) : (
                      <CircularProgress color="primary" size={20} />
                    )}
                  </IconButton>
                  // </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                formData.owner_id = searchOwner?.id;
                return (
                  // searchOwner?.id && (
                  <TextInput
                    disabled
                    fullWidth
                    label="Owner full name"
                    format={(val) => searchOwner?.first_name}
                    validate={required()}
                    source="owner_id"
                  />
                  // )
                );
              }}
            </FormDataConsumer>
          </Grid>

          <Grid item sm={12} md={5}>
            <MyTextField
              label="Add Registered Driver 1 email or phone"
              value={driverOne}
              onChange={(event) => {
                if (searchDriverOne === false) {
                  setSearchDriverOne(null);
                }
                setDriverOne(event.target.value);
              }}
              helperText={
                searchDriverOne === false &&
                "Please enter an already registered driver email or phone!"
              }
              fullWidth
              error={searchDriverOne === false}
              InputProps={{
                endAdornment: (
                  // <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setDriverOneLoading(true);
                      const { access_token } = JSON.parse(
                        localStorage.getItem("auth")
                      );
                      axios({
                        method: "get",
                        url: `${BASE_URL}/drivers?${stringify({
                          page: 1,
                          perPage: 2,
                          filter: isNaN(driverOne)
                            ? JSON.stringify({ email: driverOne })
                            : JSON.stringify({ phone: driverOne }),
                        })}`,
                        headers: {
                          Authorization: `Bearer ${access_token}`,
                        },
                      })
                        .then((data) => {
                          console.log({ data: data?.data?.data });
                          if (data?.data?.data?.length > 0) {
                            setSearchDriverOne(data?.data?.data?.[0]);
                          } else {
                            setSearchDriverOne(false);
                            // setDriverOne("");
                          }
                        })
                        .catch((err) => {
                          console.log({ err });
                          setSearchDriverOne(false);
                          // setDriverOne("");
                        })
                        .finally(() => {
                          setDriverOneLoading(false);
                        });
                    }}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {!driverOneLoading ? (
                      <Search />
                    ) : (
                      <CircularProgress color="primary" size={20} />
                    )}
                  </IconButton>
                  // </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                formData.driverOne = searchDriverOne?.id;
                return (
                  // searchDriverOne?.id && (
                  <TextInput
                    disabled
                    fullWidth
                    label="Driver 1 Full Name"
                    format={(val) => searchDriverOne?.first_name}
                    validate={required()}
                    source="driverOne"
                  />
                  // )
                );
              }}
            </FormDataConsumer>
          </Grid>

          <Grid item sm={12} md={5}>
            <MyTextField
              label="Add Registered Driver 2 email or phone"
              value={driverTwo}
              onChange={(event) => {
                if (searchDriverTwo === false) {
                  setSearchDriverTwo(null);
                }
                setDriverTwo(event.target.value);
              }}
              helperText={
                searchDriverTwo === false &&
                "Please enter an already registered driver email or phone!"
              }
              fullWidth
              error={searchDriverTwo === false}
              InputProps={{
                endAdornment: (
                  // <InputAdornment position="end">
                  <IconButton
                    color="primary"
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setDriverTwoLoading(true);
                      const { access_token } = JSON.parse(
                        localStorage.getItem("auth")
                      );
                      axios({
                        method: "get",
                        url: `${BASE_URL}/drivers?${stringify({
                          page: 1,
                          perPage: 2,
                          filter: isNaN(driverTwo)
                            ? JSON.stringify({ email: driverTwo })
                            : JSON.stringify({ phone: driverTwo }),
                        })}`,
                        headers: {
                          Authorization: `Bearer ${access_token}`,
                        },
                      })
                        .then((data) => {
                          console.log({ data: data?.data?.data });
                          if (data?.data?.data?.length > 0) {
                            setSearchDriverTwo(data?.data?.data?.[0]);
                          } else {
                            setSearchDriverTwo(false);
                            // setDriverTwo("");
                          }
                        })
                        .catch((err) => {
                          console.log({ err });
                          setSearchDriverTwo(false);
                          // setDriverTwo("");
                        })
                        .finally(() => {
                          setDriverTwoLoading(false);
                        });
                    }}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {!driverTwoLoading ? (
                      <Search />
                    ) : (
                      <CircularProgress color="primary" size={20} />
                    )}
                  </IconButton>
                  // </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm={12} md={5}>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                formData.driverTwo = searchDriverTwo?.id;
                return (
                  // searchDriverTwo?.id && (
                  <TextInput
                    disabled
                    fullWidth
                    label="Driver 2 Full Name"
                    format={(val) => searchDriverTwo?.first_name}
                    // validate={required()}
                    source="driverTwo"
                  />
                  // )
                );
              }}
            </FormDataConsumer>
          </Grid>
          {/* <ReferenceInput
            source="owners"
            reference="owners"
            filterToQuery={(searchText) => {
              // if (searchText?.length < 12) {
              //   return;
              // }
              return isNaN(owner)
                ? { email: searchText }
                : { phone: searchText };
            }}
          >
            <AutocompleteInput optionText="email" />
          </ReferenceInput> */}
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  SimpleFormIterator: {
    // display: "flex",
    // direction: "column",
    width: "100%",
    alignItem: "center",
    justifyContent: "center",
    // backgroundColor: "green",
  },
  first: {
    // backgroundColor: "red",
    float: "left",
    clear: "both",
    marginRight: "40px",
    // flex: 1,
  },
  second: {
    // backgroundColor: "yellow",
    // flex: 1,
  },
});
