import { SvgIcon } from "@material-ui/core";

export function DriverLogo(props) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20.006"
      height="20.006"
      viewBox="0 0 20.006 20.006"
    >
      <g id="Driver" transform="translate(0.25 0.25)">
        <g id="Group_9776" data-name="Group 9776" transform="translate(0 0)">
          <g
            id="Group_9773"
            data-name="Group 9773"
            transform="translate(8.699 10.386)"
          >
            <path
              id="Path_4591"
              data-name="Path 4591"
              d="M204.66,243.089a1.054,1.054,0,1,0,1.054,1.054A1.056,1.056,0,0,0,204.66,243.089Z"
              transform="translate(-203.605 -243.089)"
            />
            <path
              id="Path_4591_-_Outline"
              data-name="Path 4591 - Outline"
              d="M204.66,242.839a1.3,1.3,0,1,1-1.3,1.3A1.305,1.305,0,0,1,204.66,242.839Zm0,2.108a.8.8,0,1,0-.8-.8A.805.805,0,0,0,204.66,244.947Z"
              transform="translate(-203.605 -243.089)"
            />
          </g>
          <g
            id="Group_9774"
            data-name="Group 9774"
            transform="translate(1.712 7.421)"
          >
            <path
              id="Path_4592"
              data-name="Path 4592"
              d="M50.683,178.186a7.009,7.009,0,0,1,5.486-2.18,8.005,8.005,0,0,0-.389-2.318,13.186,13.186,0,0,1-7.444,2.335,15.151,15.151,0,0,1-7.872-2.333,8.046,8.046,0,0,0-.382,2.226,7.089,7.089,0,0,1,5.657,2.186,8.334,8.334,0,0,1,1.579,6.077c.265.026.533.04.805.04a8.137,8.137,0,0,0,.969-.06A8.259,8.259,0,0,1,50.683,178.186Zm4.578-3.417.133.31-2.548,1.1-.134-.309Zm-11.684,1.406-2.548-1.1.133-.31,2.548,1.1Zm3.155,1.532a1.392,1.392,0,1,1,1.392,1.392A1.393,1.393,0,0,1,46.732,177.707Z"
              transform="translate(-40.082 -173.688)"
            />
            <path
              id="Path_4592_-_Outline"
              data-name="Path 4592 - Outline"
              d="M55.916,173.3l.1.314a8.261,8.261,0,0,1,.4,2.39l0,.251-.251,0a6.773,6.773,0,0,0-5.3,2.093,7.942,7.942,0,0,0-1.532,5.783l.027.246-.246.029a8.434,8.434,0,0,1-1,.062c-.27,0-.549-.014-.829-.041l-.256-.025.033-.255a8.017,8.017,0,0,0-1.521-5.883,6.852,6.852,0,0,0-5.467-2.1h-.258l.008-.258a8.3,8.3,0,0,1,.394-2.295l.1-.311.274.178a14.828,14.828,0,0,0,7.736,2.293,12.964,12.964,0,0,0,7.305-2.293Zm-8.529,2.944a15.537,15.537,0,0,1-6.777-2.164,7.814,7.814,0,0,0-.265,1.592,10.41,10.41,0,0,1,1.895.2L40.7,175.21l.33-.77,3.007,1.294-.266.62a6,6,0,0,1,2.157,1.584,8.4,8.4,0,0,1,1.665,6.012c.179.012.356.018.529.018.224,0,.458-.011.7-.033a8.323,8.323,0,0,1,1.673-5.913,5.986,5.986,0,0,1,2.189-1.595l-.3-.693,3.008-1.294.331.77-2,.858a9.838,9.838,0,0,1,2.183-.306,7.764,7.764,0,0,0-.273-1.682,13.446,13.446,0,0,1-6.738,2.181,1.641,1.641,0,1,1-1.513-.021Zm.736,2.608a1.141,1.141,0,1,0-1.142-1.142A1.143,1.143,0,0,0,48.123,178.848Z"
              transform="translate(-40.082 -173.688)"
            />
          </g>
          <g id="Group_9775" data-name="Group 9775">
            <path
              id="Path_4593"
              data-name="Path 4593"
              d="M9.753,0a9.753,9.753,0,1,0,9.753,9.753A9.764,9.764,0,0,0,9.753,0Zm0,18.288A8.388,8.388,0,1,1,18.141,9.9,8.4,8.4,0,0,1,9.753,18.292Z"
              transform="translate(0 -0.004)"
            />
            <path
              id="Path_4593_-_Outline"
              data-name="Path 4593 - Outline"
              d="M9.753-.246a10,10,0,1,1-10,10A10.014,10.014,0,0,1,9.753-.246Zm0,19.506a9.5,9.5,0,1,0-9.5-9.5A9.514,9.514,0,0,0,9.753,19.26Zm0-17.993A8.638,8.638,0,1,1,1.115,9.9,8.647,8.647,0,0,1,9.753,1.267Zm0,16.775A8.138,8.138,0,1,0,1.615,9.9,8.147,8.147,0,0,0,9.753,18.042Z"
              transform="translate(0 -0.004)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
